import queryString from 'query-string';
import React, {Component, Fragment} from 'react';
import {toast} from 'react-toastify';

import {inject, observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router';
import {Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {PromiseButton} from '../../../components/promiseButton';
import {AppService} from '../../../dataServices/appService';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {email: string; password: string; resetCode: string; resetReadOnly: boolean; error: string};

@inject(MainStoreName)
@observer
export class ResetPasswordPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const search = queryString.parse(this.props.location.search);
    this.state = {
      email: (search.email as string) || '',
      password: '',
      resetCode: (search.code as string) || '',
      resetReadOnly: !!(search.code as string),
      error: '',
    };
  }
  render() {
    return (
      <Fragment>
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="slider-content" />
                </div>
              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <h4 className="mb-0">
                  <div>Reset Password</div>
                  <span>Please enter your new password</span>
                </h4>
                <Row className="divider" />
                <div>
                  <Form onSubmit={this.login}>
                    <Row form>
                      <Col md={6} style={{display: 'none'}}>
                        <FormGroup>
                          <Label for="reset email">Email</Label>
                          <Input
                            value={this.state.email}
                            onChange={(a) => this.setState({email: a.target.value})}
                            name="reset email"
                            id="exampleEmail"
                            placeholder="Email Address"
                            autoComplete={'false'}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} style={{display: 'none'}}>
                        <FormGroup>
                          <Label for="reset password">Reset Code</Label>
                          <Input
                            readOnly={this.state.resetReadOnly}
                            value={this.state.resetCode}
                            onChange={(a) => this.setState({resetCode: a.target.value})}
                            name="reset code"
                            id="reset code"
                            placeholder="Reset Code"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="new password">New Password</Label>
                          <Input
                            value={this.state.password}
                            onChange={(a) => this.setState({password: a.target.value})}
                            type="password"
                            name="new password"
                            id="new password"
                            placeholder="New Password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="divider" />
                    <div className="d-flex align-items-center">
                      <div className="ml-auto">
                        <PromiseButton type="submit" color="primary" size="lg" onClick={this.login}>
                          Reset Password
                        </PromiseButton>
                      </div>
                    </div>
                    <Row className="divider" />
                    {this.state.error && (
                      <div className="d-flex align-items-center">
                        <div className="alert alert-danger" role="alert">
                          {this.state.error}
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }

  private login = async (e: any) => {
    e.preventDefault();
    if (!this.state.password) {
      return;
    }
    this.setState({error: ''});
    const result = await AppService.partnerClient.resetPassword(
      {
        email: this.state.email,
        code: this.state.resetCode,
        newPassword: this.state.password,
      },
      {
        404: ({error}) => {
          this.setState({error});
        },
      }
    );
    if (result) {
      toast('Your password has been set.', {type: 'success'});
      this.props.history.push('/auth/login');
    }
  };
}
