import React, {Fragment} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {AdminRoute} from '../../components/adminRoute';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';

export const PartnersPages = (props: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader {...props} />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
        </div>
      </div>
    </div>
  </Fragment>
);
