import {inject, observer} from 'mobx-react';
import React, {Fragment} from 'react';
import Hamburger from 'react-hamburgers';
import {Button} from 'reactstrap';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';
import {themeStoreDefaultProps, ThemeStoreName, ThemeStoreProps} from '../../../store/theme/store';
import {AppMobileMenu} from '../appMobileMenu';

type Props = ThemeStoreProps & MainStoreProps;
type State = {};

@inject(ThemeStoreName, MainStoreName)
@observer
export class AppLogo extends React.Component<Props, State> {
  static defaultProps = themeStoreDefaultProps;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  toggleEnableClosedSidebar = () => {
    this.props.themeStore.setEnableClosedSidebar(!this.props.themeStore.enableClosedSidebar);
  };

  private logout = () => {
    this.props.mainStore.setJWT(null);
    this.props.mainStore.setUser(null);
    this.props.themeStore.setEnableClosedSidebar(false);
  };

  render() {
    const {enableClosedSidebar, enableMobileMenu} = this.props.themeStore;

    const {} = this.state;
    return (
      <Fragment>
        <div className="app-header__logo">
          {enableMobileMenu ? <Button onClick={this.logout}>Logout</Button> : <div className="logo-src" />}
          <div className="header__pane ml-auto">
            <div onClick={this.toggleEnableClosedSidebar}>
              <Hamburger active={enableClosedSidebar} type="elastic" />
            </div>
          </div>
        </div>
        <AppMobileMenu />
      </Fragment>
    );
  }
}
