import {inject, observer} from 'mobx-react';
import moment from 'moment';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {Button, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row, Table} from 'reactstrap';
import {Area, AreaChart, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {StateSwitch} from '../../../components/stateSwitch';
import {HttpPartnerLedger, HttpPartnerLedgerLineItem} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {FormatUtils} from '../../../utils/formatUtils';
import {Utils} from '../../../utils/utils';
import {PromiseButton} from '../../../components/promiseButton';
import {CsvUtils} from '../../../utils/csvUtils';

type Props = MainStoreProps & RouteComponentProps<{}> & {};
type State = {
  gettingLedger: boolean;
  ledger: HttpPartnerLedger;
};

@inject(MainStoreName)
@observer
export class LedgerPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {gettingLedger: true, ledger: null};
  }

  componentDidMount(): void {
    this.getLedger();
  }

  private async getLedger() {
    this.setState({gettingLedger: true});

    const result = Utils.partnerMerge(
      await Promise.all(
        this.props.mainStore.partners.map((p) => AppService.partnerClient.getLedger({partnerId: p.id}, {}))
      ),
      (current, next) => {
        for (const lineItem of next.ledger.lineItems) {
          const found = current.ledger.lineItems.find(
            (a) => a.date === lineItem.date && a.type === lineItem.type && a.origin.type === lineItem.origin.type
          );
          if (found) {
            found.amount += lineItem.amount;
          } else {
            current.ledger.lineItems.push(lineItem);
          }
        }
        return current;
      }
    );

    if (result) {
      this.setState({
        gettingLedger: false,
        ledger: result.ledger,
      });
    }
  }
  private downloadClaimReport = async (ledgerItem: HttpPartnerLedgerLineItem) => {
    const result = await AppService.partnerClient.ledgerDebitReport(
      {partnerId: this.props.mainStore.primaryPartner.id, debitLedgerId: ledgerItem.id},
      {...MainStore.handleError(400)}
    );
    if (result) {
      CsvUtils.saveRawFile(`${ledgerItem.date}.csv`, result.report);
      /*
      if(ledgerItem.type==='debit' && ledgerItem.origin.reportApproximate){
        alert("Please note this report is approximate. ")
      }
*/
    }
  };

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`Payout Ledger`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={null}
        />
        {this.renderPayoutLedger()}
      </ReactCSSTransitionGroup>
    );
  }

  renderPayoutLedger() {
    if (this.state.gettingLedger) {
      return <Loading />;
    }
    const {ledger} = this.state;
    const mostRecentPayout = this.state.ledger.lineItems
      .sort((a, b) => +new Date(b.date) - +new Date(a.date))
      .find((a) => a.type === 'debit');
    return (
      <>
        <div className="card no-shadow bg-transparent no-border rm-borders mb-3">
          <Card>
            <Row className="no-gutters">
              <Col md="12" lg="4">
                <ListGroup flush>
                  <ListGroupItem className="bg-transparent">
                    <div className="widget-content p-0">
                      <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">Amount Available</div>
                            <div className="widget-subheading" />
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers text-success">
                              {FormatUtils.formatMoney(
                                Utils.sum(this.state.ledger.lineItems, (a) =>
                                  a.type === 'credit' ? a.amount : -a.amount
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
              <Col md="12" lg="4">
                <ListGroup flush>
                  <ListGroupItem className="bg-transparent">
                    <div className="widget-content p-0">
                      <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">Most Recent Payout</div>
                            <div className="widget-subheading">
                              {mostRecentPayout && moment(mostRecentPayout.date).format('dddd, MMMM Do YYYY')}
                            </div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers text-success">
                              {mostRecentPayout ? FormatUtils.formatMoney(mostRecentPayout.amount) : 'N/A'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
              <Col md="12" lg="4">
                <ListGroup flush>
                  <ListGroupItem className="bg-transparent">
                    <div className="widget-content p-0">
                      <div className="widget-content-outer">
                        <div
                          className="widget-content-wrapper"
                          style={{flex: 1, alignItems: 'center', justifyContent: 'flex-end'}}
                        >
                          <Button onClick={this.payout} color={'primary'} size={'lg'}>
                            Pay Out
                          </Button>
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </Col>
            </Row>
          </Card>
        </div>
        <Row>
          <Col md="12">
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                  Ledger
                </div>
              </CardHeader>
              <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                    <ResponsiveContainer height="100%">
                      <AreaChart
                        data={this.state.ledger.lineItems
                          .filter((a) => a.type === 'credit')
                          .sort((a, b) => +a.date - +b.date)
                          .map((a) => ({
                            name: moment(a.date).format('MM-DD-YYYY'),
                            value: a.amount,
                          }))
                          .reverse()}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <Tooltip formatter={(a) => <>{FormatUtils.formatMoney(a as number)}</>} />
                        <defs>
                          <linearGradient id="colorRev" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="var(--primary)" stopOpacity={0.7} />
                            <stop offset="90%" stopColor="var(--primary)" stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="name" />
                        <Area
                          dot={true}
                          type="monotoneX"
                          dataKey="value"
                          stroke="var(--primary)"
                          strokeWidth="3"
                          fillOpacity={1}
                          fill="url(#colorRev)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <CardBody className="pt-2 pb-0">
                <div className="scroll-area-md">
                  <PerfectScrollbar>
                    <Table hover striped className="mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Amount</th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ledger.lineItems
                          .sort((a, b) => +b.date - +a.date)
                          .map((a) => (
                            <tr key={a.id}>
                              <td>{moment(a.date).format('MM-DD-YYYY')}</td>
                              <td style={a.type === 'credit' ? {color: 'green'} : {color: 'red'}}>
                                {FormatUtils.formatMoney(a.amount)}
                              </td>
                              <StateSwitch state={() => a.origin.type}>
                                {{
                                  commissionResult: () =>
                                    a.origin.type === 'commissionResult' && (
                                      <td>
                                        Claim Revenue Earned{' '}
                                        <Link to={`/dashboard/reports/claims?month=${a.origin.commissionMonth}`}>
                                          {moment(a.origin.commissionMonth + '-01').format('MMM YYYY')}
                                        </Link>
                                      </td>
                                    ),
                                  manual: () =>
                                    a.origin.type === 'manual' && (
                                      <td>
                                        {a.origin.note || 'Payout Via Check'}{' '}
                                        <PromiseButton
                                          color="primary"
                                          size="sm"
                                          onClick={() => this.downloadClaimReport(a)}
                                        >
                                          Download Report
                                        </PromiseButton>
                                      </td>
                                    ),
                                  stripe: () =>
                                    a.origin.type === 'stripe' && (
                                      <td>
                                        Payout Via Stripe{' '}
                                        <PromiseButton
                                          color="primary"
                                          size="sm"
                                          onClick={() => this.downloadClaimReport(a)}
                                        >
                                          Download Report
                                        </PromiseButton>
                                      </td>
                                    ),
                                  'stripe-request': () =>
                                    a.origin.type === 'stripe-request' && (
                                      <td>
                                        Pending Payout Request{' '}
                                        <PromiseButton
                                          color="primary"
                                          size="sm"
                                          onClick={() => this.downloadClaimReport(a)}
                                        >
                                          Download Report
                                        </PromiseButton>
                                      </td>
                                    ),
                                  'gift-card': () => a.origin.type === 'gift-card' && <td>Payout Via Gift Card</td>,
                                  'gift-card-request': () =>
                                    a.origin.type === 'gift-card-request' && <td>Pending Payout Request</td>,
                                    claimCommissionResult: () => <></>
                                }}
                              </StateSwitch>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </PerfectScrollbar>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  private payout = () => {
    this.props.history.push('/account/payout');
  };
}
