import React, {Component, Fragment} from 'react';

import {inject, observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {PromiseButton} from '../../../components/promiseButton';
import {AppService} from '../../../dataServices/appService';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {email: string; password: string; error: string};

@inject(MainStoreName)
@observer
export class LoginPage extends Component<Props, State> {
  state = {
    email: '',
    password: '',
    error: '',
  };

  render() {
    return (
      <Fragment>
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="slider-content" />
                </div>
              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <h4 className="mb-0">
                  <div>Welcome back,</div>
                  <span>Please sign in to your account.</span>
                </h4>
                <Row className="divider" />
                <div>
                  <Form onSubmit={this.login}>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">Email</Label>
                          <Input
                            value={this.state.email}
                            onChange={(a) => this.setState({email: a.target.value})}
                            name="email"
                            id="exampleEmail"
                            placeholder="Email Address"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="examplePassword">Password</Label>
                          <Input
                            value={this.state.password}
                            onChange={(a) => this.setState({password: a.target.value})}
                            type="password"
                            name="password"
                            id="examplePassword"
                            placeholder="Password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="divider" />
                    <div className="d-flex align-items-center">
                      <div className="ml-auto">
                        <PromiseButton type="submit" color="primary" size="lg" onClick={this.login}>
                          Login to Dashboard
                        </PromiseButton>
                        <div>
                          <Link to={'/auth/forgot-password'}>Forgot Password</Link>
                        </div>
                      </div>
                    </div>
                    <Row className="divider" />
                    {this.state.error && (
                      <div className="d-flex align-items-center">
                        <div className="alert alert-danger" role="alert">
                          {this.state.error}
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }

  private login = async (e: any) => {
    e.preventDefault();
    this.setState({error: ''});
    const result = await AppService.partnerClient.login(
      {
        email: this.state.email,
        password: this.state.password,
      },
      {
        401: (error) => {
          this.setState({error: 'These credentials are invalid'});
        },
        400: ({error}) => {
          this.setState({error});
        },
      }
    );
    if (result) {
      this.props.mainStore.setJWT(result.jwt);
      this.props.mainStore.setUser(result.user);
      this.props.history.push('/');
    }
  };
}
