import React, {Fragment} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {AdminRoute} from '../../components/adminRoute';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {ClaimsReportPage} from './claimsReport';
import {InstallReportPage} from './installReport';
import {LedgerPage} from './ledger';

export const DashboardPages = (props: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader {...props} />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <AdminRoute path={`${props.match.url}/reports/install`} component={InstallReportPage} />
          <AdminRoute path={`${props.match.url}/reports/claims`} component={ClaimsReportPage} />
          <AdminRoute path={`${props.match.url}/ledger`} component={LedgerPage} />
        </div>
      </div>
    </div>
  </Fragment>
);
