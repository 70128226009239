import React, {Fragment} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {AdminRoute} from '../../components/adminRoute';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {AccountBusinessPage} from './business/';
import {AccountPartnerPage} from './partner';
import {AccountPayoutPage} from './payout';
import {AccountPartnerRequestsPage} from './requests';

export const AccountPages = (props: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader {...props} />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <AdminRoute path={`${props.match.url}/business`} component={AccountBusinessPage} />
          <AdminRoute path={`${props.match.url}/partner`} component={AccountPartnerPage} />
          <AdminRoute path={`${props.match.url}/payout`} component={AccountPayoutPage} />
          <AdminRoute path={`${props.match.url}/requests`} component={AccountPartnerRequestsPage} />
        </div>
      </div>
    </div>
  </Fragment>
);
