import {faBusinessTime, faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {inject, observer} from 'mobx-react';
import {useObserver} from 'mobx-react-lite';
import React, {Component, FC, useEffect, useState} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router-dom';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  CustomInput,
  DropdownMenu,
  DropdownToggle,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {LInput, LSelect} from '../../../components/lInput';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {
  AccountInvitePartnerRequest,
  HttpAccountUser,
  HttpPartner,
  HttpPartnerExtendedSimple,
  HttpPartnerGroupTreeItemSimple,
} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {useEffectAsync} from '../../../hooks/useEffectAsync';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {useStores} from '../../../store/stores';
import {FormatUtils} from '../../../utils/formatUtils';
import {PartnerUtils} from '../../../utils/partnerUtils';
import {Utils} from '../../../utils/utils';

export const AccountPartnerPage: FC<RouteComponentProps<any> & {}> = (props) => {
  const {mainStore} = useStores();
  const [partners, setPartners] = useState<HttpPartnerExtendedSimple[]>(null);
  const [showCreateCard, setShowCreateCard] = useState(false);
  const [showInvitePartner, setShowInvitePartner] = useState(false);

  const [newCard, setNewCard] = useState<{companyName?: string; vanityUrl?: string}>({});
  const [newPartner, setNewPartner] = useState<Omit<AccountInvitePartnerRequest, 'partnerId'>>({
    commissionAmount: '0',
    sendEmailToParent: true,
      serviceType: 'cleverrx',
  });
  const [selectedPartnerId, setSelectedPartnerId] = useState(mainStore.primaryPartner.id);

  useEffectAsync(async () => {
    const results = await Promise.all(
      mainStore.partners.map((p) =>
        AppService.partnerClient.getPartnerExtended(
          {
            partnerId: p.id,
          },
          {
            ...MainStore.handleError(404, () => {
              props.history.push('/');
            }),
          }
        )
      )
    );

    for (const result of results) {
      if (result) {
        const rootParent = result.partner.groupTree;
        while (true) {
          if (!result.partner.groupTree) {
            result.partner.groupTree = rootParent;
            break;
          }
          if (result.partner.groupTree.partnerId === result.partner.id) {
            break;
          }
          result.partner.groupTree = result.partner.groupTree.children[0];
        }
      }
    }
    setPartners(results.filter((a) => a && a.partner).map((a) => a.partner));
  }, []);

  async function createCard() {
    /*if (!newCard.companyName) {
      toast('Please provide a company name', {type: 'error'});
      return;
    }*/
    if (!newCard.vanityUrl) {
      toast('Vanity URL is required!', {type: 'error'});
      return;
    }
    if (newCard.vanityUrl && !newCard.vanityUrl.match(/^[a-z0-9A-Z_-]+$/)) {
      toast('Your Vanity URL must only contain letters, numbers, dashes, or underscores', {type: 'error'});
      return;
    }
    const result = await AppService.partnerClient.createCard(
      {
        companyName: newCard.companyName,
        vanityUrl: newCard.vanityUrl,
        partnerId: selectedPartnerId,
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      mainStore.setUser(result.user);
      setShowCreateCard(false);
      toast('Your card has been created! ' + (newCard.vanityUrl ? 'You vanity URL is pending approval.' : ''), {
        type: 'success',
      });
    }
  }
  async function invitePartner() {
    if (!newPartner.emailAddress) {
      toast('You must provide an email address', {type: 'error'});
      return;
    }
    if (!newPartner.partnerName) {
      toast('You must provide a partner name', {type: 'error'});
      return;
    }
    if (!newPartner.firstName) {
      toast('You must provide a first name', {type: 'error'});
      return;
    }
    if (!newPartner.lastName) {
      toast('You must provide a last name', {type: 'error'});
      return;
    }
    if (!newPartner.address1) {
      toast('You must provide an address', {type: 'error'});
      return;
    }
    if (!newPartner.city) {
      toast('You must provide an address', {type: 'error'});
      return;
    }
    if (!newPartner.state) {
      toast('You must provide an address', {type: 'error'});
      return;
    }
    if (!newPartner.zip) {
      toast('You must provide an address', {type: 'error'});
      return;
    }

    const result = await AppService.partnerClient.invitePartner(
      {...newPartner, partnerId: selectedPartnerId},
      {...MainStore.handleError(400)}
    );
    if (result) {
      setShowInvitePartner(false);
      toast('Your Partner Invite Request has been sent! ', {type: 'success'});
      props.history.push('/account/requests');
    }
  }

  return useObserver(() => (
    <ReactCSSTransitionGroup
      component="div"
      transitionName="TabsAnimation"
      transitionAppear={true}
      transitionAppearTimeout={0}
      transitionEnter={false}
      transitionLeave={false}
    >
      <PageTitle
        heading={`Partner`}
        subheading=""
        icon="pe-7s-medal icon-gradient bg-tempting-azure"
        actions={
          <UncontrolledDropdown className="d-inline-block">
            <DropdownToggle color="primary" className="btn-shadow" caret>
              <span className="btn-icon-wrapper pr-2 opacity-7">
                <FontAwesomeIcon icon={faBusinessTime} />
              </span>
              Actions
            </DropdownToggle>
            <DropdownMenu right>
              <NavItem>
                <NavLink href="#" onClick={() => setShowCreateCard(true)}>
                  <span>Create New Card</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={() => setShowInvitePartner(true)}>
                  <span>Invite New Partner</span>
                </NavLink>
              </NavItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        }
      />
      {!partners ? (
        <Loading />
      ) : (
        <Row>
          <Col md="5">
            <Card className="main-card mb-3">
              <CardBody>
                <PartnerTreeWrapper partners={partners} />
              </CardBody>
            </Card>
          </Col>
          <Col md="7">
            <AccountEdit />
          </Col>
        </Row>
      )}
      {showCreateCard && (
        <Modal isOpen={true}>
          <>
            <ModalHeader toggle={() => setShowCreateCard(false)}>New Card</ModalHeader>
            <ModalBody>
              <Form>
                <Row>
                  <Col md={12}>
                    <h5>
                      You can create a new card below. Cards have their own unique Member ID and Group Code for
                      reporting purposes. You may also optionally specify a Company Name, which will be displayed to
                      your users, and a Vanity URL which you can share with your users. <br />
                      <br />
                      Note: Vanity URLs require approval before going live. <br />
                    </h5>
                  </Col>
                </Row>
                {mainStore.partners.length > 1 && (
                  <Row style={{marginTop: 20, marginBottom: 20}}>
                    <Col md={12}>
                      <h5>
                        Which partner would you like to create this card under:
                        {mainStore.user.partners.map((p) => (
                          <Button
                            key={p.id}
                            style={{width: '100%', marginTop: 5, marginBottom: 5}}
                            color={selectedPartnerId === p.id ? 'primary' : 'secondary'}
                            onClick={() => {
                              setSelectedPartnerId(p.id);
                            }}
                          >
                            {p.partnerName} ({p.startingGroupCode}-{p.startingMemberId})
                          </Button>
                        ))}
                      </h5>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Company Name'}
                      placeholder={'ABC Incorporated'}
                      value={newCard.companyName}
                      onChange={(e) => setNewCard({...newCard, companyName: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Vanity URL'}
                      placeholder={'abc_inc'}
                      value={newCard.vanityUrl}
                      onChange={(e) => setNewCard({...newCard, vanityUrl: e.target.value})}
                    />
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <PromiseButton onClick={() => createCard()} color={'primary'}>
                Create New Card
              </PromiseButton>
            </ModalFooter>
          </>
        </Modal>
      )}
      {showInvitePartner && (
        <Modal isOpen={true}>
          <>
            <ModalHeader toggle={() => setShowInvitePartner(false)}>Invite Partner</ModalHeader>
            <ModalBody>
              <Form style={{maxHeight: '60vh', overflowY: 'scroll', overflowX: 'hidden'}}>
                <Row>
                  <Col md={12}>
                    <h5>
                      Here you can invite a new Partner under you. Fill out the information below as best you can and
                      you will hear from a member of the Clever RX Partner Success team soon. <br />
                      <br />
                      Note: Partner invitations require approval before going live. <br />
                    </h5>
                  </Col>
                </Row>
                {mainStore.partners.length > 1 && (
                  <Row style={{marginTop: 20, marginBottom: 20}}>
                    <Col md={12}>
                      <h5>
                        Which partner would you like to create this new partner under:
                        {mainStore.user.partners.map((p) => (
                          <Button
                            key={p.id}
                            style={{width: '100%', marginTop: 5, marginBottom: 5}}
                            color={selectedPartnerId === p.id ? 'primary' : 'secondary'}
                            onClick={() => {
                              setSelectedPartnerId(p.id);
                            }}
                          >
                            {p.partnerName} ({p.startingGroupCode}-{p.startingMemberId})
                          </Button>
                        ))}
                      </h5>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Partner Name'}
                      placeholder={'ABC Incorporated'}
                      value={newPartner.partnerName}
                      onChange={(e) => setNewPartner({...newPartner, partnerName: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      type={'number'}
                      label={'Commission Amount'}
                      value={newPartner.commissionAmount}
                      min={0.1}
                      step={0.01}
                      max={partners[0].commission.commissionAmount}
                      onChange={(e) => setNewPartner({...newPartner, commissionAmount: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Company Name'}
                      placeholder={'ABC Incorporated'}
                      value={newPartner.companyName}
                      onChange={(e) => setNewPartner({...newPartner, companyName: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Vanity URL'}
                      placeholder={'abc_inc'}
                      value={newPartner.vanityUrl}
                      onChange={(e) => setNewPartner({...newPartner, vanityUrl: e.target.value})}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <LInput
                      label={'First Name'}
                      placeholder={'Chris'}
                      value={newPartner.firstName}
                      onChange={(e) => setNewPartner({...newPartner, firstName: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Last Name'}
                      placeholder={'Jones'}
                      value={newPartner.lastName}
                      onChange={(e) => setNewPartner({...newPartner, lastName: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Email Address'}
                      placeholder={'abc@inc.com'}
                      value={newPartner.emailAddress}
                      onChange={(e) => setNewPartner({...newPartner, emailAddress: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Phone Number'}
                      placeholder={'888-555-1958'}
                      value={newPartner.phoneNumber}
                      onChange={(e) => setNewPartner({...newPartner, phoneNumber: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Address 1'}
                      placeholder={'5279 West 18th Street'}
                      value={newPartner.address1}
                      onChange={(e) => setNewPartner({...newPartner, address1: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Address 2'}
                      placeholder={'Suite 105'}
                      value={newPartner.address2}
                      onChange={(e) => setNewPartner({...newPartner, address2: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <LInput
                      label={'City'}
                      placeholder={'Queens'}
                      value={newPartner.city}
                      onChange={(e) => setNewPartner({...newPartner, city: e.target.value})}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for={'stateSelect'}>State</Label>
                    <Select
                      id={'stateSelect'}
                      onChange={(v: any) => setNewPartner({...newPartner, state: v.value})}
                      value={PartnerUtils.getStates().find((a) => a.value === newPartner.state)}
                      options={PartnerUtils.getStates()}
                    />
                  </Col>
                  <Col md={4}>
                    <LInput
                      label={'Postal Code'}
                      placeholder={'85847'}
                      value={newPartner.zip}
                      onChange={(e) => setNewPartner({...newPartner, zip: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LSelect
                      menuPosition={'fixed'}
                      label={'Contact Type'}
                      value={PartnerUtils.getAccountContactsTypes().find((a) => a.value === newPartner.contactType)}
                      options={PartnerUtils.getAccountContactsTypes()}
                      onChange={(e) => setNewPartner({...newPartner, contactType: (e as any).value})}
                    />
                  </Col>
                  <Col md={6} style={{height: '90px', display: 'flex', alignItems: 'center'}}>
                    <CustomInput
                      type="checkbox"
                      id={'sendToDocusign'}
                      label="BCC Welcome Email To Me"
                      checked={newPartner.sendEmailToParent}
                      onChange={(e) => setNewPartner({...newPartner, sendEmailToParent: e.target.checked})}
                    />
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <PromiseButton onClick={invitePartner} color={'primary'}>
                Invite Partner
              </PromiseButton>
            </ModalFooter>
          </>
        </Modal>
      )}
    </ReactCSSTransitionGroup>
  ));
};

function PartnerTreeWrapper(props: {partners: HttpPartnerExtendedSimple[]}) {
  const [showCommissions, setShowCommissions] = useState(false);
  const [collapsedPartners, setCollapsedPartners] = useState<string[]>([]);
  const [uncollapsablePartners, setUncollapsablePartners] = useState<string[]>([]);

  function expandAll() {
    setCollapsedPartners([]);
  }
  function collapseAll() {
    let collapsedPartners: string[] = [];
    for (const partner of props.partners) {
      const partnerId = partner.id;
      const getChildren = (groupTree: HttpPartnerGroupTreeItemSimple, isTopYet: boolean): string[] => {
        if (!isTopYet && groupTree.partnerId === partnerId) {
          isTopYet = true;
        }
        const partnerIds: string[] = [];
        if (isTopYet) {
          partnerIds.push(groupTree.partnerId);
        } else {
        }
        return partnerIds.concat(...groupTree.children.map((c) => getChildren(c, isTopYet)));
      };

      const ids = getChildren(partner.groupTree, false);
      collapsedPartners.push(...ids);
    }
    if (props.partners.length === 1) {
      collapsedPartners = collapsedPartners.filter((a) => a !== props.partners[0].id);
    }
    setCollapsedPartners(collapsedPartners);
  }

  function isCollapsed(id: string) {
    return collapsedPartners.some((a) => a === id);
  }

  function toggleCollapse(id: string) {
    if (isCollapsed(id)) {
      setCollapsedPartners(collapsedPartners.filter((a) => a !== id));
    } else {
      setCollapsedPartners([...collapsedPartners, id]);
    }
  }

  useEffect(() => {
    collapseAll();
  }, [props.partners]);

  return (
    <>
      <CardTitle>Partner Tree</CardTitle>
      <a
        href={'#'}
        onClick={(e) => {
          expandAll();
          e.preventDefault();
        }}
      >
        Expand All
      </a>
      &nbsp;&nbsp;&nbsp;
      <a
        href={'#'}
        onClick={(e) => {
          collapseAll();
          e.preventDefault();
        }}
      >
        Collapse All
      </a>
      &nbsp;&nbsp;&nbsp;
      <a
        href={'#'}
        onClick={(e) => {
          setShowCommissions(!showCommissions);
          e.preventDefault();
        }}
      >
        {showCommissions ? 'Hide' : 'Show'} Commissions
      </a>
      <div className={'partner-tree'} style={{paddingTop: 10}}>
        <ul>
          {props.partners.map((partner) => (
            <li>
              <PartnerTree
                item={partner.groupTree}
                showCommissions={showCommissions}
                rootPartner={partner}
                toggleCollapse={toggleCollapse}
                uncollapsablePartners={uncollapsablePartners}
                testIsCollapsed={isCollapsed}
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

function PartnerTree({
  item,
  rootPartner,
  testIsCollapsed,
  showCommissions,
  uncollapsablePartners,
  toggleCollapse,
}: {
  rootPartner: HttpPartner;
  item: HttpPartnerGroupTreeItemSimple;
  showCommissions: boolean;
  uncollapsablePartners: string[];
  testIsCollapsed: (id: string) => boolean;
  toggleCollapse: (id: string) => void;
}) {
  const selected = item.partnerId === rootPartner.id;
  const isCollapsed = testIsCollapsed(item.partnerId);
  const dontShowToggle =
    uncollapsablePartners.some((a) => a === item.partnerId) || (item.children.length === 0 && item.cards.length === 0);
  return (
    <div>
      {!dontShowToggle && (
        <a
          href={'#'}
          style={{marginLeft: 5, marginRight: 5}}
          onClick={(e) => {
            toggleCollapse(item.partnerId);
            e.preventDefault();
          }}
        >
          {isCollapsed ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus} />}
        </a>
      )}
      <span style={{fontWeight: selected ? 'bold' : 'normal'}}>
        {item.startingGroupCode}-{item.startingMemberId} {item.partnerName}
      </span>
      {showCommissions && item.commissionAmount >= 0 && (
        <span style={{paddingLeft: 5}} className={'money'}>
          {FormatUtils.formatMoney(item.commissionAmount)}
        </span>
      )}
      {/*{groupTree.account ? (
          <Link to={`/excel/account/${groupTree.account.id}`} className={'tree-account'}>
            ({groupTree.account.email})
          </Link>
        ) : (
          ''
        )}*/}
      {!isCollapsed && (
        <ul key={item.partnerId}>
          {item.children.map((a) => (
            <li key={a.partnerId}>
              <PartnerTree
                item={a}
                rootPartner={rootPartner}
                testIsCollapsed={testIsCollapsed}
                showCommissions={showCommissions}
                toggleCollapse={toggleCollapse}
                uncollapsablePartners={uncollapsablePartners}
              />
            </li>
          ))}
          {item.cards.length > 0 && (
            <li style={{color: 'green'}}>
              Cards
              <ul>
                {item.cards.map((card) => (
                  <li key={card.groupCode + '-' + card.memberId}>
                    <span style={{color: 'green'}}>
                      {card.groupCode} - {card.memberId} <b>{card.companyName}</b>
                      <i>
                        &nbsp;&nbsp;
                        {card.urlSlug ? (
                          <a target={'_blank'} href={`https://partner.cleverrx.com/${card.urlSlug}`}>
                            {card.urlSlug}
                          </a>
                        ) : (
                          ''
                        )}
                      </i>
                    </span>
                    {/*
              {card.account ? (
                <Link to={`/excel/account/${card.account.id}`} className={'tree-account'}>
                  ({card.account.email})
                </Link>
              ) : (
                ''
              )}
*/}
                  </li>
                ))}
              </ul>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

@inject(MainStoreName)
@observer
export class AccountEdit extends Component<
  MainStoreProps,
  {
    account?: HttpAccountUser;
    isModified: boolean;
  }
> {
  constructor(props: {}, context: any) {
    super(props, context);

    this.state = {isModified: false, account: this.props.mainStore.user};
  }

  render() {
    const account = this.state.account;
    return (
      <Card className="main-card mb-3">
        <CardBody>
          <CardTitle>Details</CardTitle>
          <Form>
            <Row>
              <Col md={8}>
                <LInput
                  label={'Account Owner'}
                  value={account.accountOwner}
                  onChange={(e) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, accountOwner: e.target.value},
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <LInput
                  label={'Email'}
                  value={account.email}
                  onChange={(e) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, email: e.target.value},
                    })
                  }
                />
              </Col>
              <Col md={3}>
                <LInput
                  label={'Phone Number'}
                  value={account.phoneNumber}
                  onChange={(e) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, phoneNumber: e.target.value},
                    })
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <LInput
                  label={'Address 1'}
                  onChange={(e) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, address1: e.target.value},
                    })
                  }
                  value={account.address1}
                />
              </Col>
              <Col md={6}>
                <LInput
                  label={'Address 2'}
                  onChange={(e) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, address2: e.target.value},
                    })
                  }
                  value={account.address2}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <LInput
                  value={account.city}
                  onChange={(e) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, city: e.target.value},
                    })
                  }
                  label={'City'}
                  type={'text'}
                  name={'city'}
                  placeholder="Optional"
                  autoComplete={'disabled'}
                />
              </Col>
              <Col md={4}>
                <Label for={'stateSelect'}>State</Label>
                <Select
                  menuPosition={'fixed'}
                  id={'stateSelect'}
                  onChange={(v: any) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, state: v.value},
                    })
                  }
                  value={PartnerUtils.getStates().find((a) => a.value === account.state)}
                  options={PartnerUtils.getStates()}
                  autoComplete={'disabled'}
                />
              </Col>
              <Col md={2}>
                <LInput
                  value={account.zip}
                  onChange={(e) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, zip: e.target.value},
                    })
                  }
                  label={'Zip'}
                  type={'text'}
                  name={'zip'}
                  placeholder="Optional"
                  autoComplete={'disabled'}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <LSelect
                  menuPosition={'fixed'}
                  label={'Contact Type'}
                  value={PartnerUtils.getAccountContactsTypes().find((a) => a.value === account.contactType)}
                  options={PartnerUtils.getAccountContactsTypes()}
                  onChange={(e) =>
                    this.setState({
                      isModified: true,
                      account: {...this.state.account, contactType: (e as any).value},
                    })
                  }
                />
              </Col>
            </Row>
            <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
              <PromiseButton
                disabled={!this.state.isModified}
                onClick={this.save}
                className="btn-pill btn-shadow mr-3"
                color="primary"
              >
                Save Changes
              </PromiseButton>
            </Row>
          </Form>
        </CardBody>
      </Card>
    );
  }

  private save = async () => {
    const result = await AppService.partnerClient.updateAccount(
      {
        accountId: this.state.account.id,
        email: this.state.account.email,
        phoneNumber: this.state.account.phoneNumber,
        accountOwner: this.state.account.accountOwner,
        address1: this.state.account.address1,
        address2: this.state.account.address2,
        city: this.state.account.city,
        state: this.state.account.state,
        zip: this.state.account.zip,
      },
      {
        400: (e) => {
          this.props.mainStore.setError(e.error);
        },
      }
    );
    if (result) {
      this.setState({isModified: false, account: result.user});
      this.props.mainStore.setUser(result.user);
      toast('Your account details have been updated!', {type: 'success'});
    }
  };
}
