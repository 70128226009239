import {HttpMarketingMaterial} from '../dataServices/app.generated';

interface CompositeAnimation {
  start: (callback?: any) => void;
  stop: () => void;
}

export class Utils {
  static noop(): any {
    return null;
  }

  static async animatedSequence(items: (CompositeAnimation | (() => Promise<void>))[]) {
    for (const item of items) {
      if (typeof item === 'function') {
        await item();
      } else {
        await new Promise((res) => {
          const compItem = item as CompositeAnimation;
          compItem.start(() => {
            res();
          });
        });
      }
    }
  }

  static timeout(timeout: number): Promise<void> {
    return new Promise((res) => {
      setTimeout(() => {
        res();
      }, timeout);
    });
  }
  static timeoutDie(timeout: number, message: string = 'TIMEOUT'): Promise<any> {
    return new Promise((res, rej) => {
      setTimeout(() => {
        rej(message as any);
      }, timeout);
    });
  }

  static rangeC<T>(length: number, callback: (ind: number) => T): T[] {
    return this.range(length).map(callback);
  }

  static range(length: number) {
    const items = [];
    for (let i = 0; i < length; i++) {
      items.push(i);
    }
    return items;
  }

  static async killPromiseTimer<T>(promise: Promise<T>, timeout: number): Promise<T> {
    const result = await Promise.race([promise, Utils.timeoutDie(timeout)]);
    return result as T;
  }

  static toMoney(amount: number) {
    return '$' + amount.toFixed(2);
  }

  static exclude<T, TKey extends keyof T>(obj: T, key: TKey): Omit<T, TKey> {
    const result = {...(obj as any)};
    delete result[key];
    return result;
  }

  static groupBy<T, TKey>(items: T[], predicate: (pred: T) => TKey): {key: TKey; items: T[]}[] {
    const groups: {key: TKey; items: T[]}[] = [];
    for (const item of items) {
      const key = predicate(item);
      let group = groups.find((a) => a.key === key);
      if (!group) {
        groups.push((group = {key, items: []}));
      }
      group.items.push(item);
    }
    return groups;
  }

  static p<T>(callback: () => Promise<T>): Promise<T> {
    return Promise.resolve().then(callback);
  }

  static switch<T, TValue>(value: keyof T, keys: {[p in keyof T]: TValue}): TValue {
    return keys[value];
  }

  static sum<T>(items: T[], callback: (a: T) => number) {
    let sum = 0;
    for (const item of items) {
      sum += callback(item);
    }
    return sum;
  }

  static mapObject<T>(materials: T) {
    const results: {key: keyof T; items: T[keyof T]}[] = [];
    for (const safeKey of Utils.safeKeys(materials)) {
      results.push({key: safeKey, items: materials[safeKey]});
    }
    return results;
  }

  private static safeKeys<T>(item: T): [keyof T] {
    return (Object.keys(item) as unknown) as [keyof T];
  }

  static partnerMerge<TResponse>(
    responses: (TResponse | undefined)[],
    callback: (current: TResponse, next: TResponse) => TResponse
  ): TResponse {
    let response: TResponse = responses[0];
    for (let i = 1; i < responses.length; i++) {
      if (responses[i]) {
        response = callback(response, responses[i]);
      }
    }
    return response;
  }

  static unique<T>(strings: T[]): T[] {
    return strings.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  static sortMonthRan(left: string, right: string) {
    const l = left.split('-');
    const r = right.split('-');
    const a = parseInt(l[0]) * 1000 + parseInt(l[1]);
    const b = parseInt(r[0]) * 1000 + parseInt(r[1]);
    return a - b;
  }

  static sortMarketingMaterials(materials: HttpMarketingMaterial[]) {
    return materials.sort((a, b) => {
      if (a.category === 'TOP 5 MOST RECOMMENDED RESOURCES') return -1;
      return a.category.localeCompare(b.category);
    });
  }
}
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
