export class CsvUtils {
  static saveFile<T>(filename: string, items: T[], columns: {display: string; field: keyof T}[]) {
    let text = '';
    for (const column of columns) {
      text += `"${column.display}",`;
    }
    text = text.slice(0, -1);
    text += '\r\n';
    for (const item of items) {
      for (const column of columns) {
        text += `"${item[column.field]}",`;
      }
      text = text.slice(0, -1);
      text += '\r\n';
    }

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  static saveRawFile<T>(filename: string, body: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(body));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
