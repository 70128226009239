import {inject, observer} from 'mobx-react';
import moment from 'moment';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {Button, ButtonGroup, Card, CardBody, CardTitle, Col, Row, Table} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {HttpPartnerRequest} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';
import {Utils} from '../../../utils/utils';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {
  requests?: HttpPartnerRequest[];
  filter: 'pending' | 'denied' | 'approved' | 'all';
};

@inject(MainStoreName)
@observer
export class AccountPartnerRequestsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filter: 'pending',
    };
  }

  async componentDidMount() {
    const result = Utils.partnerMerge(
      await Promise.all(
        this.props.mainStore.partners.map((p) => AppService.partnerClient.getPartnerRequests({partnerId: p.id}, {}))
      ),
      (current, next) => {
        current.requests.push(...next.requests);
        return current;
      }
    );

    if (result) {
      this.setState({requests: result.requests});
    }
  }

  render() {
    const filteredRequests = this.state.requests
      ? this.state.requests.filter((a) => {
          switch (this.state.filter) {
            case 'pending':
              return a.approved === undefined || a.approved === null;
            case 'denied':
              return a.approved === false;
            case 'approved':
              return a.approved;
            case 'all':
              return true;
          }
        })
      : [];
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle heading={`Clever Requests`} subheading="" icon="pe-7s-medal icon-gradient bg-tempting-azure" />
        {!this.state.requests ? (
          <Loading />
        ) : (
          <Row>
            <Col md="7">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Pending Requests</CardTitle>
                  <ButtonGroup>
                    <Button
                      color="primary"
                      onClick={() => this.setState({filter: 'pending'})}
                      active={this.state.filter === 'pending'}
                    >
                      Pending
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.setState({filter: 'approved'})}
                      active={this.state.filter === 'approved'}
                    >
                      Approved
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.setState({filter: 'denied'})}
                      active={this.state.filter === 'denied'}
                    >
                      Denied
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.setState({filter: 'all'})}
                      active={this.state.filter === 'all'}
                    >
                      All
                    </Button>
                  </ButtonGroup>
                  <Table hover striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Request</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredRequests.length === 0 && <span>There are no requests.</span>}
                      {filteredRequests
                        .sort((a, b) => +b.createdDate - +a.createdDate)
                        .map((a) => (
                          <tr key={a._id}>
                            <td>{moment(a.createdDate).format('MM-DD-YYYY')}</td>
                            {a.request.type === 'card-vanity-url' && <td>Card Vanity URL: {a.request.vanityUrl}</td>}
                            {a.request.type === 'invite-partner' && (
                              <td>
                                Invite Partner: {a.request.body.partnerName}{' '}
                                {a.request.body.vanityUrl ? `(${a.request.body.vanityUrl})` : ''}
                              </td>
                            )}
                            <td>
                              {a.approved === undefined || a.approved === null
                                ? 'Pending'
                                : a.approved === true
                                ? 'Approved'
                                : 'Denied'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </ReactCSSTransitionGroup>
    );
  }
}
