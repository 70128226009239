import {configure} from 'mobx';
import {create} from 'mobx-persist';
import {Provider} from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './assets/base.scss';
import Main from './pages';
import {MainStoreName} from './store/main/store';
import {stores} from './store/stores';

configure({enforceActions: 'always'});
const rootElement = document.getElementById('root');

/*
const cache = new InMemoryCache();

const client = new ApolloClient({
  uri: 'http://localhost:3003/graphql',
  request: operation => {
    const {jwt /!*, latitude, longitude*!/} = mainStore;
    operation.setContext({
      headers: {
        Authorization: jwt ? 'Bearer ' + jwt : '',
      },
    });
  },
  cache,
});*/

const hydrate = create({});
hydrate(MainStoreName, stores.mainStore).then(() => {
  ReactDOM.render(
    // <ApolloProvider client={client}>
    <Provider {...stores}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>,
    // </ApolloProvider>
    rootElement
  );
});
