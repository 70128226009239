import {mainStore} from '../store/main/store';

export class BaseClient {
  constructor(protected options: ControllerOptions) {}

  async transformOptions(options: RequestInit): Promise<RequestInit> {
    const {jwt /*, latitude, longitude*/} = mainStore;
    const headers = options.headers as any;
    if (jwt) {
      headers.Authorization = 'Bearer ' + jwt;
    }
    /*if (latitude !== 0 && longitude !== 0) {
            headers['x-app-location'] = `[${latitude},${longitude}]`;
        }*/
    return options;
  }
}

export interface ControllerOptions {
  baseUrl: string;
  getJwt: () => string | undefined;
  handleError: (error: string) => void;
  handleUnauthorized: (error: string) => void;
}
