import React, {Fragment} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {AdminRoute} from '../components/adminRoute';
import {AccountPages} from './account';
import {AuthPages} from './auth';
import {DashboardPages} from './dashboard';
import {MarketingPages} from './marketing';
import {PartnersPages} from './partners';

const AppMain = () => {
  return (
    <Fragment>
      <AdminRoute path="/dashboard" component={DashboardPages} />
      <AdminRoute path="/marketing" component={MarketingPages} />
      <AdminRoute path="/account" component={AccountPages} />
      <AdminRoute path="/partners" component={PartnersPages} />
      <Route path="/auth" component={AuthPages} />
      <Route exact path="/" render={() => <Redirect to="/dashboard/reports/claims" />} />
      <ToastContainer position={'bottom-right'} />
    </Fragment>
  );
};

export default AppMain;
