/*
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Masonry from 'react-masonry-css';
import {RouteComponentProps} from 'react-router';
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';
import {Utils} from '../../../utils/utils';
import './index.css';

type Props = MainStoreProps & RouteComponentProps<{}> & {};
type State = {};
const materials: {[key: string]: {title: string; image: string; pdf: string}[]} = {
  'ABOUT CLEVER RX': [
    {
      title: 'CEO Welcome Letter',
      image: '/marketing/image/CEO Welcome Letter.png',
      pdf: '/marketing/pdf/CEO Welcome Letter.pdf',
    },
    {
      title: 'What Is Clever RX Video',
      image: '/marketing/image/what-is-cleverrx.png',
      pdf: '/marketing/pdf/What is Clever RX.mp4',
    },
    {
      title: 'The Clever RX Advantage',
      image: '/marketing/image/The Clever RX Advantage.png',
      pdf: '/marketing/pdf/The Clever RX Advantage.pdf',
    },
    {
      title: 'The Clever RX Story',
      image: '/marketing/image/The Clever RX Story.png',
      pdf: '/marketing/pdf/The Clever RX Story.pdf',
    },
    {
      title: 'Clever RX Slides',
      image: '/marketing/image/Clever RX Slides.png',
      pdf: '/marketing/pdf/Clever RX Slides.pdf',
    },
    {
      title: 'Clever RX Quick Introduction Slides',
      image: '/marketing/image/Clever RX Quick Introduction Slides.png',
      pdf: '/marketing/pdf/Clever RX Quick Introduction Slides.pdf',
    },
    {
      title: 'FAQs',
      image: '/marketing/image/General Customer FAQs.png',
      pdf: '/marketing/pdf/General Customer FAQs.pdf',
    },
    {
      title: 'TOP 10 FACTS EVERY BROKER MUST KNOW',
      image: '/marketing/image/TOP 10 FACTS EVERY BROKER MUST KNOW.png',
      pdf: '/marketing/pdf/TOP 10 FACTS EVERY BROKER MUST KNOW.pdf',
    },

    {
      title: 'TOP 10 FACTS EVERY PROVIDER MUST KNOW',
      image: '/marketing/image/TOP 10 FACTS EVERY PROVIDER MUST KNOW.png',
      pdf: '/marketing/pdf/TOP 10 FACTS EVERY PROVIDER MUST KNOW.pdf',
    },
    {
      title: 'Medicare Donut Hole + Clever RX',
      image: '/marketing/image/Medicare Donut Hole Clever RX.png',
      pdf: '/marketing/pdf/Medicare Donut Hole Clever RX.pdf',
    },
    {
      title: 'Clever Circle of Life',
      image: '/marketing/image/Clever Circle of Life.jpg',
      pdf: '/marketing/pdf/Clever Circle of Life.pdf',
    },
    {
      title: 'HOW TO USE CLEVER RX',
      image: '/marketing/image/How to Use Clever RX.png',
      pdf: '/marketing/pdf/How to Use Clever RX.mp4',
    },
  ],
  'HOW TO FLYERS': [
    {
      title: 'How to Flyer Black',
      image: '/marketing/image/How to Flyer Black Add Your Logo.png',
      pdf: '/marketing/pdf/How to Flyer Black Add Your Logo.pdf',
    },
    {
      title: 'How to Flyer White',
      image: '/marketing/image/How to Flyer White Add Your Logo.png',
      pdf: '/marketing/pdf/How to Flyer White Add Your Logo.pdf',
    },
    {
      title: 'How to Flyer White - Horizontal',
      image: '/marketing/image/How to Flyer White Horizontal.png',
      pdf: '/marketing/pdf/How to Flyer White Horizontal.pdf',
    },
    {
      title: 'How to Flyer Black - Add Your Logo',
      image: '/marketing/image/How to Flyer Black Add Your Logo.png',
      pdf: '/marketing/pdf/How to Flyer Black Add Your Logo.pdf',
    },
    {
      title: 'How to Flyer White - Add Your Logo',
      image: '/marketing/image/How to Flyer White Add Your Logo.png',
      pdf: '/marketing/pdf/How to Flyer White Add Your Logo.pdf',
    },
    {
      title: 'Clever RX How to Flyer - Black With Cutout',
      image: '/marketing/image/Clever RX How to Flyer Black With Cutout and Editable URL.png',
      pdf: '/marketing/pdf/Clever RX How to Flyer Black With Cutout and Editable URL.pdf',
    },
    {
      title: 'Clever RX How To Flyer - White With Cutout',
      image: '/marketing/image/Clever RX How To Flyer White With Cutout and Editable URL.png',
      pdf: '/marketing/pdf/Clever RX How To Flyer White With Cutout and Editable URL.pdf',
    },
  ],
  ASSOCIATION: [
    {
      title: 'Association Flyer Black',
      image: '/marketing/image/Association Flyer Black.png',
      pdf: '/marketing/pdf/Association Flyer Black.pdf',
    },
    {
      title: 'Association Flyer White',
      image: '/marketing/image/Association White.png',
      pdf: '/marketing/pdf/Association White.pdf',
    },
  ],
  BROKER: [
    {
      title: 'Employer Flyer Black',
      image: '/marketing/image/Employer Black.png',
      pdf: '/marketing/pdf/Employer Black.pdf',
    },
    {
      title: 'Employer Flyer White',
      image: '/marketing/image/Employer White.png',
      pdf: '/marketing/pdf/Employer White.pdf',
    },
    {
      title: 'Partner Flyer Black',
      image: '/marketing/image/Partner Black.png',
      pdf: '/marketing/pdf/Partner Black.pdf',
    },
    {
      title: 'Partner Flyer White',
      image: '/marketing/image/Partner White.png',
      pdf: '/marketing/pdf/Partner White.pdf',
    },
    {
      title: 'LET YOUR EMPLOYEES KNOW ABOUT CLEVER RX',
      image: '/marketing/image/Employer Email.png',
      pdf: '/marketing/pdf/Employer Email.docx',
    },
  ],
  CONSUMER: [
    {
      title: 'Consumer Flyer Black',
      image: '/marketing/image/Consumer Flyer Black.png',
      pdf: '/marketing/pdf/Consumer Flyer Black.pdf',
    },
    {
      title: 'Consumer Flyer White',
      image: '/marketing/image/Consumer White.png',
      pdf: '/marketing/pdf/Consumer White.pdf',
    },
    {
      title: 'Consumer Flyer White - Horizontal',
      image: '/marketing/image/Consumer Flyer White Horizontal.png',
      pdf: '/marketing/pdf/Consumer Flyer White Horizontal.pdf',
    },
  ],
  NONPROFIT: [
    {
      title: 'Nonprofit Flyer Black',
      image: '/marketing/image/Nonprofit Black.png',
      pdf: '/marketing/pdf/Nonprofit Black.pdf',
    },
    {
      title: 'Nonprofit Flyer White',
      image: '/marketing/image/Nonprofit White.png',
      pdf: '/marketing/pdf/Nonprofit White.pdf',
    },
  ],
  VETERINARY: [
    {
      title: 'LET YOUR EMPLOYEES KNOW CLEVER RX CAN HELP YOUR PETS',
      image: '/marketing/image/Vet Email.png',
      pdf: '/marketing/pdf/Vet Email.docx',
    },
    {
      title: 'VETERINARY FLYER',
      image: '/marketing/image/Veterinary Flyer.png',
      pdf: '/marketing/pdf/Veterinary Flyer.pdf',
    },
  ],
  PROVIDER: [
    {
      title: 'Provider Flyer Black',
      image: '/marketing/image/Provider Black.png',
      pdf: '/marketing/pdf/Provider Black.pdf',
    },
    {
      title: 'Provider Flyer White',
      image: '/marketing/image/Provider White.png',
      pdf: '/marketing/pdf/Provider White.pdf',
    },
  ],
};
const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};
@inject(MainStoreName)
@observer
export class MarketingMaterialsPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`Marketing Materials`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={null}
        />
        {Utils.mapObject(materials).map((groupedMaterials) => (
          <Row key={groupedMaterials.key}>
            <Col md="12">
              <Card className="mb-3">
                <CardBody className="pt-2 pb-0">
                  <CardTitle>{groupedMaterials.key}</CardTitle>
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {groupedMaterials.items.map((material) => (
                      <div key={material.title}>
                        <a target={'_blank'} href={material.pdf}>
                          <img style={style.image} src={material.image} />
                        </a>
                        <div>
                          <a target={'_blank'} href={material.pdf}>
                            {material.title.toUpperCase()}
                          </a>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ))}
      </ReactCSSTransitionGroup>
    );
  }
}

const style = {
  image: {width: 200},
};*/

import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Masonry from 'react-masonry-css';
import {RouteComponentProps} from 'react-router';
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {Utils} from '../../../utils/utils';
import './index.css';
import {AppService} from '../../../dataServices/appService';
import {HttpMarketingMaterial} from '../../../dataServices/app.generated';
import Loading from '../../../components/loading';

type Props = MainStoreProps & RouteComponentProps<{}> & {};
type State = {
  loading: boolean;
  materials: HttpMarketingMaterial[];
};

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};
@inject(MainStoreName)
@observer
export class MarketingMaterialsPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      loading: true,
      materials: [],
    };
  }

  async componentDidMount() {
    const result = await AppService.partnerClient.getMarketingMaterials({}, MainStore.handleError(400));
    if (result) {
      this.setState({materials: result.materials, loading: false});
    }
  }

  onDownloadMaterial = async (title: string) => {
    await AppService.partnerClient.downloadMaterial(
      {title, partnerId: this.props.mainStore.primaryPartner.id},
      {'400': () => {}}
    );
  };

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`Marketing Materials`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={null}
        />
        {this.state.loading && <Loading />}
        {Utils.groupBy(
          Utils.sortMarketingMaterials(this.state.materials),
          (a) => a.category
        ).map((groupedMaterials) => (
          <Row key={groupedMaterials.key}>
            <Col md="12">
              <Card className="mb-3">
                <CardBody className="pt-2 pb-0">
                  <CardTitle>{groupedMaterials.key}</CardTitle>
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {groupedMaterials.items.map((material) => (
                      <div key={material.title}>
                        <a target={'_blank'} href={material.url}>
                          <img style={style.image} src={material.image} />
                        </a>
                        <div>
                          <a
                            target={'_blank'}
                            href={material.url}
                            onClick={() => this.onDownloadMaterial(material.title)}
                          >
                            {material.title.toUpperCase()}
                          </a>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ))}
      </ReactCSSTransitionGroup>
    );
  }
}

const style = {
  image: {width: 200},
};
