import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {inject, observer} from 'mobx-react';
import moment from 'moment';
import queryString from 'query-string';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Bar} from 'react-chartjs-2';
import CountUp from 'react-countup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {RouteComponentProps} from 'react-router';
import Select from 'react-select';
import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {AccountCommissionResultResponse, HttpCommissionResult} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {CsvUtils} from '../../../utils/csvUtils';
import {FormatUtils} from '../../../utils/formatUtils';
import {Utils} from '../../../utils/utils';

type Props = MainStoreProps & RouteComponentProps<{month: string}> & {};
type State = {
  commissionResult?: HttpCommissionResult;
  lastCommissionResult?: HttpCommissionResult;
  partnerResults?: AccountCommissionResultResponse['partnerResults'];
  partnerOwnedCards?: AccountCommissionResultResponse['partnerOwnedCards'];
  allCards?: AccountCommissionResultResponse['allCards'];
  gettingDownloadReport: boolean;
  month: string;
  months?: string[];
  noData: boolean;
};

@inject(MainStoreName)
@observer
export class ClaimsReportPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    const search = queryString.parse(this.props.location.search);
    this.state = {
      noData: false,
      gettingDownloadReport: false,
      commissionResult: null,
      partnerResults: null,
      month: (search.month as string) || null,
      months: [],
    };
  }

  async componentDidMount() {
    await this.startDownloadReport();
    await this.getDownloadReport();
  }

  private async startDownloadReport() {
    this.setState({gettingDownloadReport: true});

    const monthsResult = Utils.partnerMerge(
      await Promise.all(
        this.props.mainStore.partners.map((p) =>
          AppService.partnerClient.getCommissionResultMonths({partnerId: p.id}, {})
        )
      ),
      (current, next) => {
        current.months.push(...next.months);
        current.months = Utils.unique(current.months);
        return current;
      }
    );

    if (!monthsResult || monthsResult.months.length === 0) {
      this.setState({
        gettingDownloadReport: false,
        noData: true,
      });
      return;
    }

    monthsResult.months = monthsResult.months.sort(Utils.sortMonthRan);
    await new Promise((res) =>
      this.setState(
        {months: monthsResult.months, month: this.state.month || monthsResult.months[monthsResult.months.length - 1]},
        res
      )
    );
  }

  private async getDownloadReport() {
    this.setState({gettingDownloadReport: true});

    const result = Utils.partnerMerge(
      await Promise.all(
        this.props.mainStore.partners.map((p) =>
          AppService.partnerClient.getCommissionResult({month: this.state.month, partnerId: p.id}, {})
        )
      ),
      (current, next) => {
        current.allCards.push(...next.allCards);
        current.partnerResults.push(...next.partnerResults);
        current.partnerOwnedCards.push(...next.partnerOwnedCards);
        if (!current.commissionResult && next.commissionResult) {
          current.commissionResult = next.commissionResult;
        }
        if (current.commissionResult && next.commissionResult) {
          // current.commissionResult.claims.push(...next.commissionResult.claims);
          current.commissionResult.numberOfClaims += next.commissionResult.numberOfClaims;
          current.commissionResult.totalAmountDue += next.commissionResult.totalAmountDue;
          current.commissionResult.totalAmountSaved += next.commissionResult.totalAmountSaved;
          current.commissionResult.averagePercentSaved += next.commissionResult.averagePercentSaved;
        }

        if (!current.lastCommissionResult && next.lastCommissionResult) {
          current.lastCommissionResult = next.lastCommissionResult;
        }
        if (current.lastCommissionResult && next.lastCommissionResult) {
          // current.lastCommissionResult.claims.push(...next.lastCommissionResult.claims);
          current.lastCommissionResult.numberOfClaims += next.lastCommissionResult.numberOfClaims;
          current.lastCommissionResult.totalAmountDue += next.lastCommissionResult.totalAmountDue;
          current.lastCommissionResult.totalAmountSaved += next.lastCommissionResult.totalAmountSaved;
          current.lastCommissionResult.averagePercentSaved += next.lastCommissionResult.averagePercentSaved;
        }
        return current;
      }
    );

    if (result && result.commissionResult) {
      this.setState({
        gettingDownloadReport: false,
        commissionResult: result.commissionResult,
        partnerOwnedCards: result.partnerOwnedCards,
        partnerResults: result.partnerResults,
        allCards: result.allCards,
        lastCommissionResult: result.lastCommissionResult,
      });
    } else {
      this.setState({
        gettingDownloadReport: false,
        noData: true,
      });
    }
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={
            this.state.gettingDownloadReport || !this.state.commissionResult
              ? `Claims Report`
              : `Claims Report For ${moment(this.state.month + '-01').format('MMMM')}`
          }
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          /*actions={
            <UncontrolledDropdown className="d-inline-block">
              <DropdownToggle color="primary" className="btn-shadow" caret>
                <span className="btn-icon-wrapper pr-2 opacity-7">
                  <FontAwesomeIcon icon={faBusinessTime} />
                </span>
                Actions
              </DropdownToggle>
              <DropdownMenu right>
                <NavItem>
                  <NavLink href="#" onClick={() => this.exportClaims()}>
                    <span>Export Claims For This Month</span>
                  </NavLink>
                </NavItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          }*/
        />
        {this.renderDownloadReports()}
      </ReactCSSTransitionGroup>
    );
  }

  renderDownloadReports() {
    if (this.state.noData) {
      return (
        <Row>
          <Col md="5">
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                  Claim Data
                </div>
                <div className="btn-actions-pane-right" style={{width: 200}}>
                  <Select
                    isSearchable={false}
                    placeholder={'Select Month'}
                    value={this.state.month}
                    getOptionLabel={(a) => moment(a + '-01').format('MMMM YYYY')}
                    options={this.state.months.reverse()}
                    onChange={(month: any) => this.setState({month}, this.getDownloadReport)}
                  />
                </div>
              </CardHeader>
              <CardBody className="pt-2 pb-0">
                <div className="scroll-area-md">
                  <h3>Sorry, there were no claims found for this period</h3>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
    if (this.state.gettingDownloadReport || !this.state.commissionResult) {
      return <Loading />;
    }
    const {commissionResult} = this.state;

    const myCards = this.state.partnerOwnedCards.map((a) => a.groupCode + '-' + a.memberId);
    const claims = commissionResult.groupedClaims
      .filter((a) => myCards.some((c) => c === a.groupCode + '-' + a.memberId))
      .map((item) => {
        const cc = this.state.allCards.find(
          (a) => a.groupCode + '-' + a.memberId === item.groupCode + '-' + item.memberId
        );
        return {
          // ...item,
          groupCode: item.groupCode,
          memberId: item.memberId,
          partnerName: cc.partnerName,
          earned: item.totalAmountDue,
          compensable: item.compensable,
          totalAmountSaved: item.totalAmountSaved,
        };
      })
      .sort((a, b) => b.earned - a.earned);

    for (const card of this.state.allCards) {
      if (!claims.some((a) => a.groupCode + '-' + a.memberId === card.groupCode + '-' + card.memberId)) {
        claims.push({
          partnerName: card.partnerName,
          groupCode: card.groupCode,
          memberId: card.memberId,
          totalAmountSaved: 0,
          compensable: 0,
          // items: [],
          earned: 0,
        });
      }
    }

    const daysData: {name: string; Claims: number; '$ Revenue': string; '$ Savings': string}[] = [];
    for (const ind of Utils.range(moment(this.state.month + '-01').daysInMonth())) {
      const key = moment(this.state.month + '-01')
        .set('date', ind + 1)
        .format('YYYY-MM-DD');
      const day = commissionResult.byDateClaims.find((a) => a.date === key);
      if (!day) {
        daysData.push({
          name: moment(this.state.month + '-01')
            .set('date', ind + 1)
            .format('Do'),
          Claims: 0,
          '$ Savings': '0',
          '$ Revenue': '0',
        });
      } else {
        daysData.push({
          name: moment(this.state.month + '-01')
            .set('date', ind + 1)
            .format('Do'),
          Claims: day.compensable,
          '$ Savings': (-day.totalAmountSaved).toFixed(2),
          '$ Revenue': day.totalAmountDue.toFixed(2),
        });
      }
    }

    const dataset = {
      label: 'Claims',
      backgroundColor: '#ffa100',
      borderColor: '#ac6d00',
      borderWidth: 1,
      borderCapStyle: 'round' as const,
      data: daysData.map((a) => a.Claims),
    };

    /**/
    const isThisMonth = moment(this.state.commissionResult.monthRan + '-01').isBefore(moment().startOf('month'));
    return (
      <>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader className="card-header-tab z-index-6">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
                  Performance
                </div>
                <div className="btn-actions-pane-right" style={{width: 200, zIndex: 100000000}}>
                  <Select
                    menuPosition={'absolute'}
                    value={this.state.month}
                    getOptionLabel={(a) => moment(a + '-01').format('MMMM YYYY')}
                    options={this.state.months.reverse()}
                    isSearchable={false}
                    placeholder={'Select Month'}
                    onChange={(month: any) => this.setState({month}, this.getDownloadReport)}
                  />
                </div>
                <div style={{margin: '0 10px'}}>
                  <PromiseButton color={'primary'} onClick={this.exportClaims}>
                    Export Month As CSV
                  </PromiseButton>
                </div>
              </CardHeader>
              <Row className="no-gutters">
                {!this.props.mainStore.zeroDollarPartner && (
                  <Col sm="6" md="4" xl="4">
                    <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                      <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning" />
                        <i className="lnr-laptop-phone text-dark opacity-8" />
                      </div>
                      <div className="widget-chart-content">
                        <div className="widget-subheading">Claim Revenue</div>
                        <div className="widget-numbers">
                          <CountUp
                            start={0}
                            end={this.state.commissionResult.totalAmountDue}
                            separator=","
                            decimals={0}
                            decimal="."
                            prefix="$"
                            useEasing={true}
                            suffix=""
                            duration={4}
                          />
                        </div>
                        {this.state.lastCommissionResult && isThisMonth && (
                          <div className="widget-description opacity-8 text-focus">
                            <div
                              className={
                                'd-inline pr-1 ' +
                                (this.state.commissionResult.totalAmountDue >
                                this.state.lastCommissionResult.totalAmountDue
                                  ? 'text-success'
                                  : 'text-danger')
                              }
                            >
                              <FontAwesomeIcon
                                icon={
                                  this.state.commissionResult.totalAmountDue >
                                  this.state.lastCommissionResult.totalAmountDue
                                    ? faAngleUp
                                    : faAngleDown
                                }
                              />
                              <span className="pl-1">
                                {Math.abs(
                                  Math.round(
                                    (1 -
                                      this.state.commissionResult.totalAmountDue /
                                        this.state.lastCommissionResult.totalAmountDue) *
                                      100
                                  )
                                )}
                                %
                              </span>
                            </div>
                            from the previous month
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="divider m-0 d-md-none d-sm-block" />
                  </Col>
                )}

                <Col sm="6" md="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-9 bg-danger" />
                      <i className="lnr-graduation-hat text-white" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Number Of Claims</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.commissionResult.numberOfClaims}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={3}
                        />
                      </div>
                      {this.state.lastCommissionResult && isThisMonth && (
                        <div className="widget-description opacity-8 text-focus">
                          <div
                            className={
                              'd-inline pr-1 ' +
                              (this.state.commissionResult.numberOfClaims >
                              this.state.lastCommissionResult.numberOfClaims
                                ? 'text-success'
                                : 'text-danger')
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.commissionResult.numberOfClaims >
                                this.state.lastCommissionResult.numberOfClaims
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                            <span className="pl-1">
                              {Math.abs(
                                Math.round(
                                  (1 -
                                    this.state.commissionResult.numberOfClaims /
                                      this.state.lastCommissionResult.numberOfClaims) *
                                    100
                                )
                              )}
                              %
                            </span>
                          </div>
                          from the previous month
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="12" md="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-9 bg-success" />
                      <i className="lnr-apartment text-white" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Amount Saved</div>
                      <div className="widget-numbers text-success">
                        <CountUp
                          start={0}
                          end={this.state.commissionResult.totalAmountSaved}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix="$"
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                      {this.state.lastCommissionResult && isThisMonth && (
                        <div className="widget-description opacity-8 text-focus">
                          <div
                            className={
                              'd-inline pr-1 ' +
                              (-this.state.commissionResult.totalAmountSaved >
                              -this.state.lastCommissionResult.totalAmountSaved
                                ? 'text-danger'
                                : 'text-success')
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                -this.state.commissionResult.totalAmountSaved >
                                -this.state.lastCommissionResult.totalAmountSaved
                                  ? faAngleDown
                                  : faAngleUp
                              }
                            />
                            <span className="pl-1">
                              {Math.abs(
                                Math.round(
                                  (1 -
                                    this.state.commissionResult.totalAmountSaved /
                                      this.state.lastCommissionResult.totalAmountSaved) *
                                    100
                                )
                              )}
                              %
                            </span>
                          </div>
                          from the previous month
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              {/*
              <CardFooter className="text-center d-block p-3">
                <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                  <span className="mr-2 opacity-7">
                    <Ionicon color="#ffffff" icon="ios-analytics-outline" beat={true} />
                  </span>
                  <span className="mr-1">View Complete Report</span>
                </Button>
              </CardFooter>
*/}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            {this.state.partnerResults.length > 0 && (
              <Card className="mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                    Partner Performance
                  </div>
                </CardHeader>
                <CardBody className="pt-2 pb-0">
                  <div className="scroll-area-md">
                    <PerfectScrollbar>
                      <Table hover striped className="mb-0">
                        <thead>
                          <tr>
                            <th>Partner Name</th>
                            {/*<th>Group Code</th>*/}
                            {/*<th>Member Id</th>*/}
                            <th>Number Of Claims</th>
                            {!this.props.mainStore.zeroDollarPartner && <th>Amount Earned</th>}
                            <th>Amount Saved</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.partnerResults
                            .sort((a, b) => b.totalAmountDue - a.totalAmountDue)
                            .map((item, index) => (
                              <tr key={item.groupCode + ' ' + item.memberId}>
                                <td>{item.partnerName}</td>
                                {/*<td>{item.groupCode}</td>*/}
                                {/*<td>{item.memberId}</td>*/}
                                <td>{item.numberOfClaims}</td>
                                {!this.props.mainStore.zeroDollarPartner && (
                                  <td>{FormatUtils.formatMoney(item.totalAmountDue)}</td>
                                )}
                                <td>{FormatUtils.formatMoney(item.totalAmountSaved)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </PerfectScrollbar>
                  </div>
                </CardBody>
              </Card>
            )}
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                  Card Activity
                </div>
              </CardHeader>
              <CardBody className="pt-2 pb-0">
                <div className="scroll-area-md">
                  <PerfectScrollbar>
                    <Table hover striped className="mb-0">
                      <thead>
                        <tr>
                          <th>Partner</th>
                          <th>Number Of Claims</th>
                          {!this.props.mainStore.zeroDollarPartner && <th>Amount Earned</th>}
                          <th>Amount Saved</th>
                        </tr>
                      </thead>
                      <tbody>
                        {claims.map((item, index) => (
                          <tr key={item.groupCode + '-' + item.memberId}>
                            <td>
                              {item.partnerName} ({item.groupCode + '-' + item.memberId})
                            </td>
                            <td>{item.compensable}</td>
                            {!this.props.mainStore.zeroDollarPartner && <td>{FormatUtils.formatMoney(item.earned)}</td>}
                            <td>{FormatUtils.formatMoney(item.totalAmountSaved)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </PerfectScrollbar>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={'6'}>
            <Card className="main-card mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-briefcase mr-3 text-muted opacity-6" />
                  Claims In {moment(this.state.month + '-01').format('MMMM')}
                </div>
              </CardHeader>
              <CardBody>
                <Bar
                  data={{
                    labels: Utils.range(moment(this.state.month + '-01').daysInMonth()).map((a) =>
                      moment(this.state.month + '-' + (a + 1)).format('MMM Do')
                    ),
                    datasets: [dataset],
                  }}
                  width={100}
                  height={50}
                  options={{
                    tooltips: {
                      mode: 'index',
                      intersect: false,
                    },
                    legend: {
                      display: false,
                    },
                    responsive: true,
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                        },
                      ],
                    },
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  private exportClaims = async () => {
    const {commissionResult} = this.state;
    CsvUtils.saveFile(
      `claims-${this.state.month}.csv`,
      commissionResult.groupedClaims.map((a) => ({
        code: a.groupCode + '-' + a.memberId,
        totalAmountDue: FormatUtils.formatMoney(a.totalAmountDue),
      })),
      [
        {
          display: 'Group Code-Member ID',
          field: 'code',
        },
        {
          display: 'Commission Amount',
          field: 'totalAmountDue',
        },
      ]
    );
  };
}
