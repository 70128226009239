import {faBusinessTime} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {inject, observer} from 'mobx-react';
import moment from 'moment';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {toast} from 'react-toastify';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {StripeJS} from 'stripejs';
import poweredByStripe from '../../../assets/images/powered_by_stripe.png';
import {PageTitle} from '../../../components/layout/pageTitle';
import {LInput, LSelect} from '../../../components/lInput';
import {PromiseButton} from '../../../components/promiseButton';
import {Config} from '../../../config';
import {AppService} from '../../../dataServices/appService';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {FormatUtils} from '../../../utils/formatUtils';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {
  bankAccount: string;
  routingNumber: string;
  account_holder_name: string;
  account_holder_type: 'individual' | 'company';
  editBankAccount: boolean;
};
declare const Stripe: StripeJS;

@inject(MainStoreName)
@observer
export class AccountBusinessPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      account_holder_name: '',
      account_holder_type: 'individual',
      bankAccount: '',
      routingNumber: '',
      editBankAccount: false,
    };
  }

  get isGiftCard() {
    return this.props.mainStore.primaryPartner.payoutStyle === 'giftcard';
  }

  render() {
    const options = [
      {
        label: 'Individual',
        value: 'individual' as const,
      },
      {
        label: 'Company',
        value: 'company' as const,
      },
    ];

    const primaryPartner = this.props.mainStore.primaryPartner;
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`Business Details`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={
            !this.isGiftCard &&
            this.props.mainStore.primaryPartner.w9 && (
              <UncontrolledDropdown className="d-inline-block">
                <DropdownToggle color="primary" className="btn-shadow" caret>
                  <span className="btn-icon-wrapper pr-2 opacity-7">
                    <FontAwesomeIcon icon={faBusinessTime} />
                  </span>
                  Actions
                </DropdownToggle>
                <DropdownMenu right>
                  <NavItem>
                    <NavLink href="#" onClick={this.requestW9}>
                      <span>Request W9</span>
                    </NavLink>
                  </NavItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )
          }
        />

        <Row>
          <Col md="6">
            {this.props.mainStore.partners.map((partner) => (
              <React.Fragment key={partner.id}>
                <Card className="main-card mb-3">
                  <CardBody>
                    <CardTitle>Details</CardTitle>
                    <Form>
                      <Row>
                        <Col md={12}>
                          <LInput disabled label={'Partner Name'} value={partner.partnerName} />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <LInput disabled value={partner.startingGroupCode} label={'Group Code'} type={'text'} />
                        </Col>
                        <Col md={6}>
                          <LInput disabled value={partner.startingMemberId} label={'Member Id'} type={'text'} />
                        </Col>
                      </Row>
                    </Form>

                    {!this.isGiftCard ? (
                      <>
                        <CardTitle>Contract</CardTitle>
                        <Form>
                          <Row>
                            <Col md={6}>
                              <LInput
                                disabled
                                label={'Commission Amount'}
                                value={FormatUtils.formatMoney(partner.commission.commissionAmount)}
                              />
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <LInput
                                  disabled
                                  label={'DocuSign'}
                                  value={
                                    partner.contract && partner.contract.docusignEnvelopeId
                                      ? `Contract In Docusign (${
                                          partner.contract.contractSigned
                                            ? 'Signed ' + moment(partner.contract.contractSigned).format('YYYY-MM-DD')
                                            : 'NOT SIGNED'
                                        })`
                                      : `No Contract On File`
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                      </>
                    ) : (
                      <>
                        <CardTitle>Commission</CardTitle>
                        <Form>
                          <Row>
                            <Col md={6}>
                              <LInput
                                disabled
                                label={'Commission Amount'}
                                value={FormatUtils.formatMoney(partner.commission.commissionAmount)}
                              />
                            </Col>
                          </Row>
                        </Form>
                      </>
                    )}
                  </CardBody>
                </Card>
              </React.Fragment>
            ))}
          </Col>

          {!this.isGiftCard && (
            <>
              <Col md="6">
                <Card className="main-card mb-3">
                  <CardBody>
                    <CardTitle>W9 Tax Form</CardTitle>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          {primaryPartner.w9 ? (
                            <LInput
                              disabled
                              label={'DocuSign'}
                              value={
                                !primaryPartner.w9.dateSigned ? `Waiting for W9 Signature` : `W9 Completed And On File`
                              }
                            />
                          ) : (
                            <PromiseButton
                              onClick={this.requestW9}
                              className="btn-pill btn-shadow mr-3"
                              color="primary"
                            >
                              Request W9
                            </PromiseButton>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {primaryPartner.commission.stripeLinkSent && (
                  <Card className="main-card mb-3">
                    <CardBody>
                      <CardTitle>Bank Account Details</CardTitle>
                      {!this.state.editBankAccount && primaryPartner.commission.stripeHasBankAccount && (
                        <>
                          <h3 className={'text-success pt-3 pb-3 text-center'}>
                            Your bank account is linked and approved
                          </h3>
                          <Row>
                            <Col md={6}>
                              <img src={poweredByStripe} style={{height: 40}} />{' '}
                            </Col>
                            <Col style={{display: 'flex', justifyContent: 'flex-end'}} md={6}>
                              <PromiseButton
                                onClick={async () => {
                                  this.setState({editBankAccount: true});
                                }}
                                className="btn-pill btn-shadow mr-3"
                                color="primary"
                              >
                                Edit Bank Account
                              </PromiseButton>
                            </Col>
                          </Row>
                        </>
                      )}

                      {(this.state.editBankAccount || !primaryPartner.commission.stripeHasBankAccount) && (
                        <>
                          <Row>
                            <Col md={6}>
                              <LInput
                                value={this.state.bankAccount}
                                label={'Bank Account'}
                                type={'text'}
                                name={'back-account'}
                                autoComplete={'disabled'}
                                onChange={(e) => this.setState({bankAccount: e.target.value})}
                              />
                            </Col>
                            <Col md={6}>
                              <LInput
                                value={this.state.routingNumber}
                                label={'Routing Number'}
                                type={'text'}
                                name={'routing-number'}
                                autoComplete={'disabled'}
                                onChange={(e) => this.setState({routingNumber: e.target.value})}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <LInput
                                value={this.state.account_holder_name}
                                label={'Account Holder Name'}
                                type={'text'}
                                name={'account-holder-name'}
                                autoComplete={'disabled'}
                                onChange={(e) => this.setState({account_holder_name: e.target.value})}
                              />
                            </Col>
                            <Col md={6}>
                              <LSelect
                                menuPosition={'fixed'}
                                label={'Account Type'}
                                value={options.find((a) => a.value === this.state.account_holder_type)}
                                options={options}
                                onChange={(e) => this.setState({account_holder_type: (e as any).value})}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <img src={poweredByStripe} style={{height: 40}} />{' '}
                            </Col>
                            <Col style={{display: 'flex', justifyContent: 'flex-end'}} md={6}>
                              {primaryPartner.commission.stripeHasBankAccount && (
                                <PromiseButton
                                  onClick={async () => this.setState({editBankAccount: false})}
                                  className="btn-pill btn-shadow mr-3"
                                  color="warning"
                                >
                                  Cancel
                                </PromiseButton>
                              )}
                              <PromiseButton
                                onClick={this.setBankAccount}
                                className="btn-pill btn-shadow mr-3"
                                color="primary"
                              >
                                Save Changes
                              </PromiseButton>
                            </Col>
                          </Row>
                        </>
                      )}
                    </CardBody>
                  </Card>
                )}
              </Col>
            </>
          )}
        </Row>
      </ReactCSSTransitionGroup>
    );
  }

  private requestW9 = async () => {
    const result = await AppService.partnerClient.requestW9(
      {
        partnerId: this.props.mainStore.primaryPartner.id,
      },
      {}
    );
    if (result) {
      toast('Your W9 request has been emailed to you.', {type: 'success'});
    }
  };

  private setBankAccount = async () => {
    const stripe = Stripe(Config.stripeKey);
    const stripeResult = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: this.state.routingNumber,
      account_number: this.state.bankAccount,
      account_holder_name: this.state.account_holder_name,
      account_holder_type: this.state.account_holder_type,
    });
    if (stripeResult.error) {
      toast('Sorry, an error has occurred: ' + stripeResult.error.message, {type: 'error'});
      return;
    }
    const result = await AppService.partnerClient.setStripeBankAccount(
      {
        partnerId: this.props.mainStore.primaryPartner.id,
        stripeBankAccountToken: stripeResult.token.id,
      },
      {...MainStore.handleError(400)}
    );
    if (result) {
      this.props.mainStore.setUser(result.user);
      toast('Your stripe bank account is set up!', {type: 'success'});
      return;
    }
  };
}
