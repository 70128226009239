import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {RouteComponentProps} from 'react-router';
import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {GetDownloadsByPartnerResponse} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {Utils} from '../../../utils/utils';

type Props = MainStoreProps & RouteComponentProps<{}> & {};
type State = {
  downloadReport?: GetDownloadsByPartnerResponse['breakdown'];
  gettingDownloadReport: boolean;
};

@inject(MainStoreName)
@observer
export class InstallReportPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      gettingDownloadReport: false,
      downloadReport: null,
    };
  }

  componentDidMount(): void {
    this.getDownloadReport();
  }

  private async getDownloadReport() {
    this.setState({gettingDownloadReport: true});

    const result = Utils.partnerMerge(
      await Promise.all(
        this.props.mainStore.partners.map((p) =>
          AppService.partnerClient.getDownloadsByPartner({month: '', partnerId: p.id}, {...MainStore.handleError(404)})
        )
      ),
      (current, next) => {
        current.breakdown.push(...next.breakdown);
        return current;
      }
    );

    if (result) {
      this.setState({gettingDownloadReport: false, downloadReport: result.breakdown});
    }
  }
  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`Install Report`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={
            null /*<UncontrolledDropdown className="d-inline-block">
              <DropdownToggle color="primary" className="btn-shadow" caret>
                <span className="btn-icon-wrapper pr-2 opacity-7">
                  <FontAwesomeIcon icon={faBusinessTime} />
                </span>
                Actions
              </DropdownToggle>
              <DropdownMenu right>
                <NavItem>
                  <NavLink
                    href="javascript:void(0);"
                    onClick={() => {
                      this.props.history.push(`a`);
                    }}
                  >
                    <span>Invite Partner Under</span>
                  </NavLink>
                </NavItem>
              </DropdownMenu>
            </UncontrolledDropdown>*/
          }
        />
        {this.renderDownloadReports()}
      </ReactCSSTransitionGroup>
    );
  }

  renderDownloadReports() {
    if (this.state.gettingDownloadReport || !this.state.downloadReport) {
      return <Loading />;
    }
    const {downloadReport} = this.state;

    const cards = downloadReport.sort((a, b) => b.count - a.count);
    const installData: {name: string; Installs: number}[] = downloadReport.map((a) => ({
      name: a.groupCode + '-' + a.memberId,
      Installs: a.count,
    }));
    console.log(installData);

    return (
      <Row>
        <Col md="12">
          <Card className="mb-3">
            <CardHeader className="card-header-tab">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                Member Installs
              </div>
            </CardHeader>
            <CardBody className="pt-2 pb-0">
              <div className="scroll-area-md">
                {cards.length === 0 ? (
                  <h3>Sorry, there were no installs found</h3>
                ) : (
                  <PerfectScrollbar>
                    <Table hover striped className="mb-0">
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th>Partner</th>
                          <th>Company Name</th>
                          <th>Installs</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cards.map((item, index) => (
                          <tr key={item.groupCode + '-' + item.memberId}>
                            <td>{index + 1}</td>
                            <td>
                              {item.partnerName} ({item.groupCode}-{item.memberId})
                            </td>
                            <td>{item.companyName}</td>
                            <td>{item.count}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </PerfectScrollbar>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
