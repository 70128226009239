import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {toast} from 'react-toastify';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {PayoutViableResponse} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {FormatUtils} from '../../../utils/formatUtils';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {
  viable: PayoutViableResponse['viable'];
  modal?: {
    title: string;
    body: string;
    button: string;
    buttonAction?: () => Promise<any>;
  };
};

@inject(MainStoreName)
@observer
export class AccountPayoutPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {viable: null};
  }

  get isGiftCard() {
    return this.props.mainStore.primaryPartner.payoutStyle === 'giftcard';
  }

  async componentDidMount() {
    const result = await AppService.partnerClient.isPayoutViable(
      {partnerId: this.props.mainStore.primaryPartner.id},
      {}
    );
    if (result) {
      this.setState({viable: result.viable});
      /*      this.setState({
        viable: {
          payoutThreshold: true,
          canPayout: false,
          contract: {[this.props.mainStore.primaryPartner.id]: false},
          contractSigned: {[this.props.mainStore.primaryPartner.id]: true},
          payout: 12.5,
          payoutThresholdAmount: 50,
          stripeBankAccount: false,
          stripeCreated: false,
          stripeSetup: false,
          w9Created: true,
          w9Signed: true,
        },
      })*/
    }
  }
  private requestW9 = async () => {
    const result = await AppService.partnerClient.requestW9(
      {
        partnerId: this.props.mainStore.primaryPartner.id,
      },
      {}
    );
    if (result) {
      toast('Your W9 request has been emailed to you.', {type: 'success'});
    }
  };

  render() {
    let items: {
      text: string;
      complete: boolean;
      buttonText?: string;
      buttonAction?: () => Promise<any>;
    }[] = [];

    if (this.state.viable) {
      const contractItems: typeof items = [];
      if (!this.isGiftCard) {
        for (const partnerId in this.state.viable.contract) {
          const partner = this.props.mainStore.partners.find((a) => a.id === partnerId);
          contractItems.push(
            ...[
              {
                text: `Contract for ${partner.startingGroupCode}-${partner.startingMemberId} Requested`,
                complete: this.state.viable.contract[partner.id],
                buttonText: 'FIX',
                buttonAction: async () => {
                  this.setState({
                    modal: {
                      title: 'Request Contract',
                      body: 'Do you want to contact your Clever RX Broker to receive a new contract?',
                      button: 'Yes',
                      buttonAction: async () => {
                        const result = await AppService.partnerClient.contactBroker(
                          {
                            reason: 'new-contract',
                            partnerId: partner.id,
                          },
                          {
                            ...MainStore.handleError(400),
                          }
                        );
                        if (result) {
                          toast('Your request has been sent!', {type: 'success'});
                        }
                      },
                    },
                  });
                },
              },
              {
                text: `Contract for ${partner.startingGroupCode}-${partner.startingMemberId} Signed`,
                complete: this.state.viable.contractSigned[partner.id],
                buttonText: 'FIX',
                buttonAction: async () => {
                  this.setState({
                    modal: {
                      title: 'Sign Contract',
                      body: 'Please check your email for a DocuSign contract and execute it.',
                      button: 'Okay',
                    },
                  });
                },
              },
            ]
          );
        }
      }
      const stripeItems: typeof items = [];

      if (!this.isGiftCard && this.state.viable.payoutThreshold) {
        stripeItems.push(
          ...[
            {
              text: `Stripe Account Created`,
              complete: this.state.viable.stripeCreated,
              buttonText: 'FIX',
              buttonAction: async () => {
                if (!this.state.viable.payoutThreshold) {
                  alert(
                    'Sorry, you cannot create your stripe account until you have hit the minimum payout threshold.'
                  );
                  return;
                }
                this.setState({
                  modal: {
                    title: 'Stripe Account',
                    body: 'Do you want to contact your Clever RX Broker to set up your stripe account?',
                    button: 'Yes',
                    buttonAction: async () => {
                      const result = await AppService.partnerClient.contactBroker(
                        {
                          reason: 'stripe-account',
                          partnerId: this.props.mainStore.primaryPartner.id,
                        },
                        {
                          ...MainStore.handleError(400),
                        }
                      );
                      if (result) {
                        toast('Your request has been sent!', {type: 'success'});
                      }
                    },
                  },
                });
              },
            },
            {
              text: `Stripe Account Configured`,
              complete: this.state.viable.stripeSetup,
              buttonText: 'FIX',
              buttonAction: async () => {
                if (!this.state.viable.stripeCreated) {
                  alert(
                    'Sorry, you cannot configure your stripe account until it has been created. Please contact your broker for more information.'
                  );
                  return;
                }
                this.setState({
                  modal: {
                    title: 'Stripe Account',
                    body: 'Click here to finish setting up your Stripe Account',
                    button: 'Okay',
                    buttonAction: async () => {
                      await AppService.partnerClient.resendStripeLink(
                        {
                          partnerId: this.props.mainStore.primaryPartner.id,
                        },
                        {...MainStore.handleError(400), ...MainStore.handleError(404)}
                      );
                      setTimeout(() => {
                        this.setState({
                          modal: {
                            title: 'Stripe Account',
                            body: 'We have sent you an email to finish setting up your stripe account',
                            button: 'Okay',
                            buttonAction: async () => {
                              this.setState({modal: undefined});
                            },
                          },
                        });
                      }, 100);
                    },
                  },
                });
              },
            },
            {
              text: `Bank Account Configured`,
              complete: this.state.viable.stripeBankAccount,
              buttonText: 'FIX',
              buttonAction: async () => {
                if (!this.state.viable.stripeCreated) {
                  alert(
                    'Sorry, you cannot configure your bank account until it has been created. Please contact your broker for more information.'
                  );
                  return;
                }

                this.setState({
                  modal: {
                    title: 'Stripe Account',
                    body: 'Please update your Bank Account information under Account > Business Details',
                    button: 'Okay',
                    buttonAction: async () => {
                      this.props.history.push('/account/business');
                    },
                  },
                });
              },
            },
          ]
        );
      }
      const w9Items: typeof items = [];
      if (!this.isGiftCard) {
        w9Items.push(
          ...[
            {
              text: `W9 Form Requested`,
              complete: this.state.viable.w9Created,
              buttonText: 'REQUEST',
              buttonAction: async () => {
                this.setState({
                  modal: {
                    title: 'W9 Tax Form',
                    body: 'Tap below to request your W9 Tax Form',
                    button: 'Request',
                    buttonAction: this.requestW9,
                  },
                });
              },
            },
            {
              text: `W9 Form Signed`,
              complete: this.state.viable.w9Signed,
              buttonText: 'FIX',
              buttonAction: async () => {
                this.setState({
                  modal: {
                    title: 'W9 Tax Form',
                    body: 'Please check your email for your DocuSign W9 Tax Form',
                    button: 'Okay',
                  },
                });
              },
            },
          ]
        );
      }

      items = [
        ...contractItems,
        ...stripeItems,
        ...w9Items,
        {
          text: `Meets Minimum Payout Threshold Of ${FormatUtils.formatMoney(this.state.viable.payoutThresholdAmount)}`,
          complete: this.state.viable.payoutThreshold,
        },
      ];
    }

    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle heading={`Commission Payout`} subheading="" icon="pe-7s-medal icon-gradient bg-tempting-azure" />
        {!this.state.viable ? (
          <Loading />
        ) : (
          <Row>
            <Col md="7">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Payout Checklist</CardTitle>
                  <ListGroup>
                    {items.map((item) => (
                      <ListGroupItem
                        key={item.text}
                        color={item.complete ? 'success' : 'danger'}
                        style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}
                      >
                        {item.text}
                        <div style={{flex: 1}} />
                        {!item.complete && item.buttonText && item.buttonAction && (
                          <PromiseButton onClick={item.buttonAction} color={'warning'}>
                            {item.buttonText}
                          </PromiseButton>
                        )}
                      </ListGroupItem>
                    ))}
                    <ListGroupItem color={'success'}>
                      <b>
                        {this.isGiftCard ? 'Amazon Gift Card' : 'Payout'} Amount Available{' '}
                        {FormatUtils.formatMoney(this.state.viable.payout)}
                      </b>
                    </ListGroupItem>
                  </ListGroup>

                  {this.state.viable.canPayout && (
                    <Row>
                      <Col style={{display: 'flex', justifyContent: 'center'}} md={12}>
                        <PromiseButton
                          size={'lg'}
                          onClick={this.payoutNow}
                          className="btn-pill btn-shadow mt-4 mb-2"
                          color="primary"
                        >
                          {this.isGiftCard ? 'Send an Amazon Gift Card for' : 'Payout'}{' '}
                          {FormatUtils.formatMoney(this.state.viable.payout)} Now
                        </PromiseButton>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {this.state.modal && (
          <Modal isOpen={true}>
            <ModalHeader toggle={() => this.setState({modal: null})}>{this.state.modal.title}</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>{this.state.modal.body}</Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <PromiseButton
                onClick={async () => {
                  if (this.state.modal.buttonAction) {
                    await this.state.modal.buttonAction();
                  }
                  this.setState({modal: null});
                }}
                color={'primary'}
              >
                {this.state.modal.button}
              </PromiseButton>
            </ModalFooter>
          </Modal>
        )}
      </ReactCSSTransitionGroup>
    );
  }

  private payoutNow = async () => {
    // eslint-disable-next-line no-restricted-globals
    const ask = confirm(
      `Are you sure you want to have ${FormatUtils.formatMoney(this.state.viable.payout)} ${
        this.isGiftCard ? `sent to you as an Amazon Gift Card?` : 'deposited into your bank account?'
      }`
    );
    if (ask) {
      const result = await AppService.partnerClient.requestPayout(
        {partnerId: this.props.mainStore.primaryPartner.id},
        {
          ...MainStore.handleError(400),
        }
      );
      if (result.success) {
        toast('Your payout request is being processed.', {type: 'success'});
        this.props.history.push('/dashboard/ledger');
      }
    }
  };
}
