import React, {Fragment} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {AdminRoute} from '../../components/adminRoute';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {MarketingMaterialsPage} from './materials';
import {CardOrderPage} from './cardOrder';

export const MarketingPages = (props: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader {...props} />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <AdminRoute path={`${props.match.url}/materials`} component={MarketingMaterialsPage} />
          <AdminRoute path={`${props.match.url}/card-order`} component={CardOrderPage} />
        </div>
      </div>
    </div>
  </Fragment>
);
