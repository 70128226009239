import {inject, observer} from 'mobx-react';
import React, {Component, Fragment} from 'react';
import MetisMenu from 'react-metismenu';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';
import RouterLink from '../../routerLink';

type Props = MainStoreProps & RouteComponentProps<any>;

@inject(MainStoreName)
@observer
class Nav extends Component<Props> {
  render() {
    const zeroDollarPartner = this.props.mainStore.zeroDollarPartner;
    const Navigation = [
      {
        icon: 'pe-7s-browser',
        label: 'Dashboard',
        content: [
          zeroDollarPartner
            ? undefined
            : {
                label: 'Payout Ledger',
                to: '/dashboard/ledger',
              },
          {
            label: 'Claims Reports',
            to: '/dashboard/reports/claims',
          },
          {
            label: 'Install Reports',
            to: '/dashboard/reports/install',
          },
        ].filter(Boolean),
      },
      {
        icon: 'pe-7s-network',
        label: 'Account',
        content: [
          {
            label: 'Partner',
            to: '/account/partner',
          },
          zeroDollarPartner
            ? undefined
            : {
                label: 'Business',
                to: '/account/business',
              },
          {
            label: 'Requests',
            to: '/account/requests',
          },
        ].filter(Boolean),
      },
      {
        icon: 'pe-7s-portfolio',
        label: 'Marketing',
        content: [
          {
            label: 'Marketing Materials',
            to: '/marketing/materials',
          },

          {
            label: 'Order Cards',
            to: '/marketing/card-order',
          },
        ],
      },
    ];

    return (
      <Fragment>
        <MetisMenu
          content={Navigation}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          LinkComponent={RouterLink}
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }
}

export const AppNav = withRouter(Nav);
