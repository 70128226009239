import React, {Fragment} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AccountActivatePage} from './accountActivate';
import {ForgotPasswordPage} from './forgotPassword';
import {LoginPage} from './login';
import {ResetPasswordPage} from './resetPassword';

export const AuthPages = ({match}: RouteComponentProps<any>) => (
  <Fragment>
    <div className="app-container">
      <Route path={`${match.url}/login`} component={LoginPage} />
      <Route path={`${match.url}/forgot-password`} component={ForgotPasswordPage} />
      <Route path={`${match.url}/reset-password`} component={ResetPasswordPage} />
      <Route path={`${match.url}/account-setup`} component={AccountActivatePage} />
    </div>
  </Fragment>
);
