import React, {Fragment} from 'react';

import Hamburger from 'react-hamburgers';

import {inject, observer} from 'mobx-react';
import {themeStoreDefaultProps, ThemeStoreName, ThemeStoreProps} from '../../../store/theme/store';

type Props = ThemeStoreProps;
type State = {
  activeSecondaryMenuMobile: boolean;
};

@inject(ThemeStoreName)
@observer
export class AppMobileMenu extends React.Component<Props, State> {
  static defaultProps = themeStoreDefaultProps;
  constructor(props: Props) {
    super(props);
    this.state = {
      activeSecondaryMenuMobile: false,
    };
  }

  toggleMobileSidebar = () => {
    this.props.themeStore.setEnableMobileMenu(!this.props.themeStore.enableMobileMenu);
  };

  render() {
    const {enableMobileMenu} = this.props.themeStore;

    return (
      <Fragment>
        <div className="app-header__mobile-menu">
          <div onClick={this.toggleMobileSidebar}>
            <Hamburger active={enableMobileMenu} type="elastic" />
          </div>
        </div>
      </Fragment>
    );
  }
}
