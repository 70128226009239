import cx from 'classnames';
import {inject, observer} from 'mobx-react';
import React, {Fragment} from 'react';
import {RouteComponentProps} from 'react-router';
import {Button} from 'reactstrap';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';
import {themeStoreDefaultProps, ThemeStoreName, ThemeStoreProps} from '../../../store/theme/store';
import {AppLogo} from '../appLogo';

type Props = ThemeStoreProps & MainStoreProps & RouteComponentProps<any>;

@inject(ThemeStoreName, MainStoreName)
@observer
export class AppHeader extends React.Component<Props> {
  static defaultProps = themeStoreDefaultProps;
  render() {
    const {headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow} = this.props.themeStore;
    return (
      <Fragment>
        <div className={cx('app-header', headerBackgroundColor, {'header-shadow': enableHeaderShadow})}>
          <AppLogo />
          <div className={cx('app-header__content', {'header-mobile-open': enableMobileMenuSmall})}>
            <div className="app-header-right">
              <Button onClick={this.logout}>Logout</Button>
              {/*
              {this.props.mainStore.user && this.props.mainStore.user.partners.length > 1 && (
                <Button color={'primary'} style={{marginLeft: 10}} onClick={this.switchPartner}>
                  Switch Partner
                </Button>
              )}
*/}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  private logout = () => {
    this.props.mainStore.setJWT(null);
    this.props.mainStore.setUser(null);
  };
}
