import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Masonry from 'react-masonry-css';
import {RouteComponentProps} from 'react-router';
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {PromiseButton} from '../../../components/promiseButton';

type Props = MainStoreProps & RouteComponentProps<{}> & {};
type State = {};

@inject(MainStoreName)
@observer
export class CardOrderPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`Order Your Clever RX Cards`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={null}
        />

        <Row>
          <Col md="12">
            <Card className="mb-3">
              <CardBody >
                <PromiseButton
                  color="primary"
                  size="lg"
                  onClick={async () => {
                    window.open(
                      'https://docs.google.com/forms/d/e/1FAIpQLSfeP6LGtcF7DmUjZ57vqF739AGQssWa-A3_m2-h2Hnx9pN_8Q/viewform',
                      '_black'
                    );
                  }}
                >
                  Click Here To Order Your Cards Now
                </PromiseButton>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
    );
  }
}

const style = {
  image: {width: 200},
};
