import React, {useRef} from 'react';
import Select from 'react-select';
import {Props as SelectProps} from 'react-select/lib/Select';
import {FormGroup, Input, InputProps, Label} from 'reactstrap';

export function LInput(
  props: {
    label: string;
  } & InputProps
) {
  const inputId = useRef('input' + ((Math.random() * 1000000) | 0));

  return (
    <FormGroup>
      <Label for={inputId.current}>{props.label}</Label>
      <Input id={inputId.current} {...props} />
    </FormGroup>
  );
}

export function LText(
  props: {
    label: string;
  } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) {
  return (
    <FormGroup>
      <Label>{props.label}</Label>
      <div
        {...props}
        style={{height: 'calc(1.5em + 0.75rem + 2px)', display: 'flex', alignItems: 'center', ...props.style}}
      />
    </FormGroup>
  );
}

export function LSelect(
  props: {
    label: string;
  } & SelectProps
) {
  return (
    <FormGroup>
      <Label>{props.label}</Label>
      <Select {...props} />
    </FormGroup>
  );
}
