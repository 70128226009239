import {action, computed, observable} from 'mobx';
import {persist} from 'mobx-persist';
import {toast} from 'react-toastify';
import {HttpAccountUser} from '../../dataServices/app.generated';

export class MainStore {
  @persist
  @observable
  jwt?: string;

  @persist('object')
  @observable
  user?: HttpAccountUser;

  @observable
  error?: string;
  /*
  @persist
  @observable
  selectedPartnerId: string;*/

  @action
  setJWT(jwt: string | undefined): void {
    this.jwt = jwt;
  }

  /*  @computed
  get partner() {
    return this.user.partners.find((a) => a.id === this.selectedPartnerId);
  }*/

  @computed
  get partners() {
    return this.user.partners || [];
  }
  @computed
  get primaryPartner() {
    return this.partners.length === 1
      ? this.partners[0]
      : this.partners.find((a) => a.id === this.user.primaryPartnerId);
  }

  @action
  setUser(user: HttpAccountUser | undefined): void {
    this.user = user;
    if (user) {
      // this.selectedPartnerId = this.user.partners[0].id;
    }
  }

  /*  @action
  setPartner(id: string) {
    this.selectedPartnerId = id;
  }*/

  @action
  logout() {
    this.setJWT(undefined);
    this.setUser(undefined);
  }

  @action
  setError(error: string) {
    this.error = error;
    toast(error, {type: 'error'});
  }

  @computed
  get zeroDollarPartner() {
    let zero = true;
    for (const partner of this.partners) {
      if (partner.commission && partner.commission.commissionAmount > 0) zero = false;
    }
    return zero;
  }

  static handleError = <T extends number>(code: T, callback?: () => void) =>
    ({
      [code]: (response: {error: string}) => {
        mainStore.setError(response.error);
        callback && callback();
      },
    } as {[key in T]: (r: {error: string}) => void});
}

export const mainStore = new MainStore();
export type MainStoreProps = {
  mainStore?: MainStore;
};
export const mainStoreDefaultProps = {mainStore: (null as unknown) as MainStore};
export const MainStoreName = 'mainStore';
