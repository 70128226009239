import {inject, observer} from 'mobx-react';
import React from 'react';
import {Redirect, Route, RouteProps} from 'react-router-dom';
import {MainStoreName, MainStoreProps} from '../store/main/store';

@inject(MainStoreName)
@observer
export class AdminRoute extends React.Component<RouteProps & MainStoreProps> {
  render() {
    const {component: Component, mainStore, ...rest} = this.props;
    const isLoggedIn = !!mainStore.jwt;
    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to={{pathname: '/auth/login', state: {from: props.location}}} />
          )
        }
      />
    );
  }
}
