import cx from 'classnames';
import {inject, observer} from 'mobx-react';
import React, {Fragment} from 'react';
import ResizeDetector from 'react-resize-detector';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {AppService} from '../dataServices/appService';
import {MainStoreName, MainStoreProps} from '../store/main/store';
import {themeStoreDefaultProps, ThemeStoreName, ThemeStoreProps} from '../store/theme/store';
import AppMain from './layout';

type Props = RouteComponentProps<any> & ThemeStoreProps & MainStoreProps;
type State = {};

@inject(ThemeStoreName, MainStoreName)
@observer
class Main extends React.Component<Props, State> {
  static defaultProps = themeStoreDefaultProps;
  constructor(props: Props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };
  }

  async componentDidMount() {
    if (this.props.mainStore.jwt) {
      await AppService.partnerClient.getUser(
        {},
        {
          '200': (r) => {
            this.props.mainStore.setUser(r.user);
          },
          401: () => {
            this.props.mainStore.setJWT(null);
            this.props.mainStore.setUser(null);
          },
        }
      );
    }
  }

  render() {
    const {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
    } = this.props.themeStore;

    return (
      <ResizeDetector
        handleWidth
        render={({width}) => (
          <Fragment>
            <div
              className={cx(
                'app-container app-theme-' + colorScheme,
                {'fixed-header': enableFixedHeader},
                {'fixed-sidebar': enableFixedSidebar || width < 1250},
                {'fixed-footer': enableFixedFooter},
                {'closed-sidebar': enableClosedSidebar || width < 1250},
                {'closed-sidebar-mobile': width < 1250},
                {'sidebar-mobile-open': enableMobileMenu},
                {'body-tabs-shadow-btn': enablePageTabsAlt}
              )}
            >
              <AppMain />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

export default withRouter(Main);
