import React, {Component, Fragment} from 'react';

import {inject, observer} from 'mobx-react';
import {RouteComponentProps} from 'react-router';
import {Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {PromiseButton} from '../../../components/promiseButton';
import {AppService} from '../../../dataServices/appService';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {email: string; password: string; error: string; showCheckEmail: boolean};

@inject(MainStoreName)
@observer
export class ForgotPasswordPage extends Component<Props, State> {
  state = {
    email: '',
    password: '',
    error: '',
    showCheckEmail: false,
  };

  render() {
    return (
      <Fragment>
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                  <div className="slider-content" />
                </div>
              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <h4 className="mb-0">
                  <div>Forgot Password?</div>
                  {!this.state.showCheckEmail && <span>Please enter your email address to reset your password</span>}
                </h4>
                <Row className="divider" />
                <div>
                  {this.state.showCheckEmail ? (
                    <>
                      <span>Please check your email now.</span>
                    </>
                  ) : (
                    <Form onSubmit={this.login}>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="exampleEmail">Email</Label>
                            <Input
                              value={this.state.email}
                              onChange={(a) => this.setState({email: a.target.value})}
                              name="email"
                              id="exampleEmail"
                              placeholder="Email Address"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="divider" />
                      <div className="d-flex align-items-center">
                        <div className="ml-auto">
                          <PromiseButton type="submit" color="primary" size="lg" onClick={this.login}>
                            Reset Password
                          </PromiseButton>
                        </div>
                      </div>
                      <Row className="divider" />
                      {this.state.error && (
                        <div className="d-flex align-items-center">
                          <div className="alert alert-danger" role="alert">
                            {this.state.error}
                          </div>
                        </div>
                      )}
                    </Form>
                  )}
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }

  private login = async (e: any) => {
    e.preventDefault();
    this.setState({error: ''});
    const result = await AppService.partnerClient.forgotPassword(
      {
        email: this.state.email,
      },
      {}
    );
    if (result) {
      this.setState({showCheckEmail: true});
      // this.props.history.push(`/auth/reset-password?email=${this.state.email}`);
    }
  };
}
